import React from 'react';
import './InputWithLabelBorderEffect.scss';

type InputWithLabelBorderEffectProps = {
  label?: string;
  beforeLabel?: string;
  notify?: string;
  [rest: string]: any;
};
const InputWithLabelBorderEffect = ({ label, beforeLabel, notify, ...rest }: InputWithLabelBorderEffectProps) => {
  return (
    <div className='input-effect-border-box'>
      <label className='input-label'>{label}</label>
      <label className='before-label'>{beforeLabel}</label>
      <input className='effect-7' {...rest} />
      <label className='notify'>{notify}</label>
      <span className='focus-border'>
        <i />
      </span>
    </div>
  );
};

export default InputWithLabelBorderEffect;
