import React, { useState } from 'react';
import { InputWithLabelBorderEffect } from '../../common/InputWithLabelBorderEffect';
import { SelectBox } from '../../common/SelectBox';
import { Popup } from '../../common/Popup';
import './ClassCreatePopup.scss';
import { SchoolApi } from '../../../lib/api';
import useCreatePopup from 'hooks/myClass/useCreatePopup';

type ClassCreatePopupProps = {
  email?: string;
  loadMyClassList(): void;
};
const gradeSelectOptions = [
  { key: '1', option: '초등학교' },
  { key: '2', option: '중학교' },
  { key: '3', option: '고등학교' },
  { key: '4', option: '기타' },
];

const ClassCreatePopupUS = ({ email, loadMyClassList }: ClassCreatePopupProps) => {
  const [className, setClassName] = useState('');
  const [classExplain, setClassExplain] = useState('');
  const [classGrade, setClassGrade] = useState('초등학교');
  const [notifyClassName, setNotifyClassName] = useState('');
  const [notifyClassExplain, setNotifyClassExplain] = useState('');
  const { isShowCreatePopup, off } = useCreatePopup();

  if (!isShowCreatePopup) return null;

  const onChangeClassName = (_className: string) => {
    setClassName(_className);

    if (_className.length === 0) {
      setNotifyClassName('Please enter your class name.');

      return false;
    } else if (_className.length > 0 && !/^[a-zA-Z0-9가-힣\s]{2,}$/.test(_className)) {
      setNotifyClassName('Please enter the class name correctly.');
      return false;
    } else {
      setNotifyClassName('');
    }
  };

  const onChangeClassExplain = (_classExplain: string) => {
    setClassExplain(_classExplain);

    if (_classExplain.length === 0) {
      setNotifyClassExplain('학급 설명을 최대 한 글자 이상 작성하여 주세요.');
      return false;
    } else if (
      _classExplain.length > 0 &&
      !/^[a-z|A-Z|0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!@#$%^&*-+=_.?/,|\s]{1,}$/.test(_classExplain)
    ) {
      setNotifyClassExplain('학급 설명을 한 글자 이상 입력해 주세요.');
      return false;
    } else {
      setNotifyClassExplain('');
    }
  };

  const onChangeClassGrade = (classGrade: string) => {
    setClassGrade(classGrade);
  };

  const onClickCreateClass = async () => {
    if (!className || !classExplain || !classGrade) {
      alert('입력하지 않은 값들이 있는지 확인해주세요.');
    } else if (notifyClassName || notifyClassExplain) {
      return;
    }
    try {
      const classResp = await SchoolApi.post('/classes', {
        className: className,
        classExplain: classExplain,
        classGrade: classGrade,
        teacherEmail: email,
      });

      if (classResp.data.status_code === 200) {
        off();
        loadMyClassList();
        return alert(classResp.data.alert_message);
      }
    } catch (error : any) {
      if (error.response.data.status_code >= 400) {
        return alert(error.response.data.alert_message);
      }
    }
  };

  return (
    <Popup>
      <div className='ClassCreatePopup'>
        <div className='class-create-title'>학급 생성</div>
        <div className='class-create-info'>
          <div className='class-create-input-box'>
            <InputWithLabelBorderEffect
              label='Class Name'
              notify={notifyClassName}
              placeholder='Class Name 입력해주세요.'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeClassName(e.target.value)}
            />
            <SelectBox label='학년' options={gradeSelectOptions} handleChangeQuestionOption={onChangeClassGrade} />
            <InputWithLabelBorderEffect
              label='학급 소개'
              notify={notifyClassExplain}
              placeholder='학급 소개를 한 글자 이상으로 입력해주세요.'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeClassExplain(e.target.value)}
            />
          </div>
          <div className='class-create-button-box'>
            <button className='class-create-button' onClick={onClickCreateClass}>
              생성
            </button>
            <button className='class-create-cancel-button' onClick={off}>
              취소
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default ClassCreatePopupUS;
