import React from 'react';
import './Header.scss';
import Headroom from 'react-headroom';
import { useHistory } from 'react-router-dom';
import { AuthApi } from '../../../lib/api';
import useSession from '../../../hooks/useSession';
import { HeaderRight } from '../HeaderRight';
import { HeaderMobileMenu } from '../HeaderMobileMenu';
import Headers from './Headers';
import SelectBox from './SelectBox';
import { selectAtom } from 'lib/common/atoms';
import { useAtom } from 'jotai';


type HeaderProps = {
  option : string;
  changeOption: any;
}



const Header = ( {option, changeOption} : HeaderProps  ) => {
  console.log(option)

  const history = useHistory();
  const { isSession, logout } = useSession();

  const onClickLogout = async () => {
    try {
      const authInfo = await AuthApi.delete('/sessions', {
        withCredentials: true,
      });

      if (authInfo.data.status_code === 200) {
        logout();
        history.go(0);
      }
    } catch (error) {
      logout();
      history.go(0);
    }
  };

  const onClickGoToPath = (path: string) => {
    const { pathname } = history.location;
    if (pathname === path) return;

    history.push(path);
  };

  const [options, setOption] = useAtom(selectAtom);
  
  const getOption = (options : string) => {
    setOption(options);
  };

  
  return (
    <Headroom className={history.location.pathname === '/' ? 'headroom-transparent' : ''}>
          <div className='Header' >
            <div className='header-wrap'>
              <div className='logo'>
                <img
                  className='school-logo'
                  alt='logo'
                  src={'/images/header/logo.png'}
                  onClick={() => onClickGoToPath('/')}
                />
              </div>
              <Headers selected={option}/>
              <div className='header-select'>
                <HeaderRight isSession={isSession} onClickLogout={onClickLogout} selected={option}/>
                <HeaderMobileMenu option={option} onClickGoToPath={onClickGoToPath} isSession={isSession} onClickLogout={onClickLogout} />
             
                   <SelectBox getOption={getOption} props={{
                option: [
                  { value: "none", name: "   " },
                  { value: "ko", name: "한국어" },
                  { value: "en", name: "English" },
                  { value: "ar", name: "العربية" },
                ]
              }} />
            
             
              </div>
             
              
            </div>
          </div>
        </Headroom>
  );
};

export default Header;