
export type eduCaseItem = {
  title: string;
  titleUS : string;
  date: Date;
  photoList: string[];
  tags: string[];
  subject?:string;
  subjectUS?:string;
};

export const eduCaseList: eduCaseItem[] = [
  {
    title: '마포중앙도서관',
    subject:'3D가상공간 제작',
    titleUS: 'mapo',
    subjectUS:'3D',
    date: new Date('2020-10-21'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%A7%88%ED%8F%AC%EC%A4%91%EC%95%99%EB%8F%84%EC%84%9C%EA%B4%80/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%A7%88%ED%8F%AC%EC%A4%91%EC%95%99%EB%8F%84%EC%84%9C%EA%B4%80/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%A7%88%ED%8F%AC%EC%A4%91%EC%95%99%EB%8F%84%EC%84%9C%EA%B4%80/3.jpg',
    ],
    tags: ['와콤 연계', '도서관'],
  },
  {
    title: '구로청소년센터',
    subject:'내가 만드는 VR세상',
    titleUS: 'goru청소년센터',
    subjectUS:'my made VR world',
    date: new Date('2020-12-01'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B5%AC%EB%A1%9C%EC%B2%AD%EC%86%8C%EB%85%84%EC%84%BC%ED%84%B0/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B5%AC%EB%A1%9C%EC%B2%AD%EC%86%8C%EB%85%84%EC%84%BC%ED%84%B0/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B5%AC%EB%A1%9C%EC%B2%AD%EC%86%8C%EB%85%84%EC%84%BC%ED%84%B0/3.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B5%AC%EB%A1%9C%EC%B2%AD%EC%86%8C%EB%85%84%EC%84%BC%ED%84%B0/4.jpg',
    ],
    tags: ['와콤 연계', '교육센터'],
  },
  {
    title: '서대문구평생학습관·융복합인재교육센터',
    subject:'내가 만드는 VR세상',
    titleUS: '서대문구평생학습관·융복합인재교육센터',
    subjectUS:'내가 만드는 VR세상',
    date: new Date('2020-10-26'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%84%9C%EB%8C%80%EB%AC%B8%EA%B5%AC%ED%8F%89%EC%83%9D%ED%95%99%EC%8A%B5%EA%B4%80%C2%B7%EC%9C%B5%EB%B3%B5%ED%95%A9%EC%9D%B8%EC%9E%AC%EA%B5%90%EC%9C%A1%EC%84%BC%ED%84%B0/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%84%9C%EB%8C%80%EB%AC%B8%EA%B5%AC%ED%8F%89%EC%83%9D%ED%95%99%EC%8A%B5%EA%B4%80%C2%B7%EC%9C%B5%EB%B3%B5%ED%95%A9%EC%9D%B8%EC%9E%AC%EA%B5%90%EC%9C%A1%EC%84%BC%ED%84%B0/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%84%9C%EB%8C%80%EB%AC%B8%EA%B5%AC%ED%8F%89%EC%83%9D%ED%95%99%EC%8A%B5%EA%B4%80%C2%B7%EC%9C%B5%EB%B3%B5%ED%95%A9%EC%9D%B8%EC%9E%AC%EA%B5%90%EC%9C%A1%EC%84%BC%ED%84%B0/3.jpg',
    ],
    tags: ['와콤 연계', '교육센터'],
  },
  {
    title: '제주 CGI 애니메이션센터',
    subject:'VR 웹툰 맛보기',
    titleUS: '서대문구평생학습관·융복합인재교육센터',
    subjectUS:'내가 만드는 VR세상',
    date: new Date('2020-11-28'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%A0%9C%EC%A3%BCCGI%EC%95%A0%EB%8B%88%EB%A9%94%EC%9D%B4%EC%85%98%EC%84%BC%ED%84%B0/1.JPG',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%A0%9C%EC%A3%BCCGI%EC%95%A0%EB%8B%88%EB%A9%94%EC%9D%B4%EC%85%98%EC%84%BC%ED%84%B0/2.JPG',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%A0%9C%EC%A3%BCCGI%EC%95%A0%EB%8B%88%EB%A9%94%EC%9D%B4%EC%85%98%EC%84%BC%ED%84%B0/3.JPG',
    ],
    tags: ['와콤 연계', '교육센터'],
  },
  {
    title: '전자신문 ETedu',
    titleUS: '전자신문 ETedu',
    date: new Date('2019-03-09'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/ETedu/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/ETedu/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/ETedu/3.jpg',
    ],
    tags: ['교육센터'],
  },
  {
    title: '경기문화창조허브',
    titleUS: '경기문화창조허브',
    date: new Date('2019-04-15'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/G%ED%97%88%EB%B8%8C/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/G%ED%97%88%EB%B8%8C/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/G%ED%97%88%EB%B8%8C/3.jpg',
    ],
    tags: ['교육센터'],
  },
  {
    title: '고양영상미디어센터',
    subject:'내가 만드는 VR세상',
    titleUS: '고양영상미디어센터',
    subjectUS:'내가 만드는 VR세상',
    date: new Date('2019-07-29'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B3%A0%EC%96%91%EC%98%81%EC%83%81%EB%AF%B8%EB%94%94%EC%96%B4%EC%84%BC%ED%84%B0/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B3%A0%EC%96%91%EC%98%81%EC%83%81%EB%AF%B8%EB%94%94%EC%96%B4%EC%84%BC%ED%84%B0/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B3%A0%EC%96%91%EC%98%81%EC%83%81%EB%AF%B8%EB%94%94%EC%96%B4%EC%84%BC%ED%84%B0/3.jpg',
    ],
    tags: ['교육센터'],
  },
  {
    title: '동아사이언스 1차',
    subject:'VR 우주탐험대',
    titleUS: '고양영상미디어센터',
    subjectUS:'내가 만드는 VR세상',
    date: new Date('2019-06-30'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8F%99%EC%95%84%EC%82%AC%EC%9D%B4%EC%96%B8%EC%8A%A41%EC%B0%A8/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8F%99%EC%95%84%EC%82%AC%EC%9D%B4%EC%96%B8%EC%8A%A41%EC%B0%A8/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8F%99%EC%95%84%EC%82%AC%EC%9D%B4%EC%96%B8%EC%8A%A41%EC%B0%A8/3.jpg',
    ],
    tags: ['교육센터'],
  },
  {
    title: 'ETedu VR 캠프',
    titleUS: 'ETedu VR 캠프',
    date: new Date('2019-07-06'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/ETeduVR%EC%BA%A0%ED%94%84/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/ETeduVR%EC%BA%A0%ED%94%84/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/ETeduVR%EC%BA%A0%ED%94%84/3.jpg',
    ],
    tags: ['교육센터'],
  },
  {
    title: '동아사이언스 2차',
    subject:'VR 우주탐험대',
    titleUS: '동아사이언스 2차',
    subjectUS:'VR 우주탐험대',
    date: new Date('2019-08-03'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8F%99%EC%95%84%EC%82%AC%EC%9D%B4%EC%96%B8%EC%8A%A42%EC%B0%A8/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8F%99%EC%95%84%EC%82%AC%EC%9D%B4%EC%96%B8%EC%8A%A42%EC%B0%A8/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8F%99%EC%95%84%EC%82%AC%EC%9D%B4%EC%96%B8%EC%8A%A42%EC%B0%A8/3.jpg',
    ],
    tags: ['교육센터'],
  },
  {
    title: '부산해운대학원 설명회',
    titleUS: '부산해운대학원 설명회',
    date: new Date('2019-08-24'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%B6%80%EC%82%B0%ED%95%B4%EC%9A%B4%EB%8C%80%ED%95%99%EC%9B%90%EC%84%A4%EB%AA%85%ED%9A%8C/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%B6%80%EC%82%B0%ED%95%B4%EC%9A%B4%EB%8C%80%ED%95%99%EC%9B%90%EC%84%A4%EB%AA%85%ED%9A%8C/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%B6%80%EC%82%B0%ED%95%B4%EC%9A%B4%EB%8C%80%ED%95%99%EC%9B%90%EC%84%A4%EB%AA%85%ED%9A%8C/3.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%B6%80%EC%82%B0%ED%95%B4%EC%9A%B4%EB%8C%80%ED%95%99%EC%9B%90%EC%84%A4%EB%AA%85%ED%9A%8C/4.jpg',
    ],
    tags: ['교육센터'],
  },
  {
    title: '블로거 교육',
    titleUS: '블로거 교육',
    date: new Date('2019-12-11'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%B8%94%EB%A1%9C%EA%B1%B0%EA%B5%90%EC%9C%A1/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%B8%94%EB%A1%9C%EA%B1%B0%EA%B5%90%EC%9C%A1/2.jpg',
    ],
    tags: ['교육센터'],
  },
  {
    title: '대화도서관',
    titleUS: '대화도서관',
    date: new Date('2018-11-24'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%ED%99%94%EB%8F%84%EC%84%9C%EA%B4%80/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%ED%99%94%EB%8F%84%EC%84%9C%EA%B4%80/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%ED%99%94%EB%8F%84%EC%84%9C%EA%B4%80/3.jpg',
    ],
    tags: ['도서관'],
  },
  {
    title: '국립어린이청소년도서관',
    subject:'VR동화세상 만들기',
    titleUS: '국DDD립어린이청소년도서관',
    subjectUS:'VRDD동화세상 만들기',
    date: new Date('2019-09-20'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B5%AD%EB%A6%BD%EC%96%B4%EB%A6%B0%EC%9D%B4%EC%B2%AD%EC%86%8C%EB%85%84%EB%8F%84%EC%84%9C%EA%B4%80/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B5%AD%EB%A6%BD%EC%96%B4%EB%A6%B0%EC%9D%B4%EC%B2%AD%EC%86%8C%EB%85%84%EB%8F%84%EC%84%9C%EA%B4%80/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B5%AD%EB%A6%BD%EC%96%B4%EB%A6%B0%EC%9D%B4%EC%B2%AD%EC%86%8C%EB%85%84%EB%8F%84%EC%84%9C%EA%B4%80/3.jpg',
    ],
    tags: ['도서관'],
  },
  {
    title: '우정공무원교육원',
    titleUS: '우정공무원교육원',
    date: new Date('2018-11-01'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%9A%B0%EC%A0%95%EA%B3%B5%EB%AC%B4%EC%9B%90%EA%B5%90%EC%9C%A1%EC%9B%90/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%9A%B0%EC%A0%95%EA%B3%B5%EB%AC%B4%EC%9B%90%EA%B5%90%EC%9C%A1%EC%9B%90/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%9A%B0%EC%A0%95%EA%B3%B5%EB%AC%B4%EC%9B%90%EA%B5%90%EC%9C%A1%EC%9B%90/3.jpg',
    ],
    tags: ['연수원'],
  },
  {
    title: '대구연수원 1차',
    titleUS: '대구연수원 1차',
    date: new Date('2019-05-09'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%EA%B5%AC%EC%97%B0%EC%88%98%EC%9B%901%EC%B0%A8/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%EA%B5%AC%EC%97%B0%EC%88%98%EC%9B%901%EC%B0%A8/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%EA%B5%AC%EC%97%B0%EC%88%98%EC%9B%901%EC%B0%A8/3.jpg',
    ],
    tags: ['연수원'],
  },
  {
    title: '대구연수원 2차',
    titleUS: '대구연수원 2차',
    date: new Date('2019-06-04'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%EA%B5%AC%EC%97%B0%EC%88%98%EC%9B%902%EC%B0%A8/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%EA%B5%AC%EC%97%B0%EC%88%98%EC%9B%902%EC%B0%A8/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%EA%B5%AC%EC%97%B0%EC%88%98%EC%9B%902%EC%B0%A8/3.jpg',
    ],
    tags: ['연수원'],
  },
  {
    title: '대성동초등학교',
    titleUS: 'DDD대성동초등학교',
    date: new Date('2018-06-18'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%EC%84%B1%EB%8F%99%EC%B4%88%EB%93%B1%ED%95%99%EA%B5%90/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%EC%84%B1%EB%8F%99%EC%B4%88%EB%93%B1%ED%95%99%EA%B5%90/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%8C%80%EC%84%B1%EB%8F%99%EC%B4%88%EB%93%B1%ED%95%99%EA%B5%90/3.jpg',
    ],
    tags: ['학교'],
  },
  {
    title: '군자초등학교',
    titleUS: '군자초등학교',
    date: new Date('2019-04-23'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B5%B0%EC%9E%90%EC%B4%88%EB%93%B1%ED%95%99%EA%B5%90/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B5%B0%EC%9E%90%EC%B4%88%EB%93%B1%ED%95%99%EA%B5%90/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EA%B5%B0%EC%9E%90%EC%B4%88%EB%93%B1%ED%95%99%EA%B5%90/3.jpg',
    ],
    tags: ['학교'],
  },
  {
    title: '민락중학교',
    titleUS: 'DDD민락중학교',
    date: new Date('2020-07-10'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%AF%BC%EB%9D%BD%EC%A4%91%ED%95%99%EA%B5%90/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EB%AF%BC%EB%9D%BD%EC%A4%91%ED%95%99%EA%B5%90/2.jpg',
    ],
    tags: ['학교'],
  },
  {
    title: '춘천애니고등학교',
    titleUS: '춘DDD천애니고등학교',
    date: new Date('2020-10-15'),
    photoList: [
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%B6%98%EC%B2%9C%EC%95%A0%EB%8B%88%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90/1.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%B6%98%EC%B2%9C%EC%95%A0%EB%8B%88%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90/2.jpg',
      'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_a7b545f15bf2469c98b1fcd9c0cc1c04/images/edu-case/%EC%B6%98%EC%B2%9C%EC%95%A0%EB%8B%88%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90/3.jpg',
    ],
    tags: ['학교'],
  },
].sort((a, b) => b.date.getTime() - a.date.getTime());
