import useSWR, { mutate } from 'swr';

const KEY = 'classUpdatePopup';
export default function useUpdatePopup() {
  const { data: isShowClassUpdatePopup = false, mutate } = useSWR<boolean>(KEY);

  const on = () => {
    mutate(true);
  };

  const off = () => {
    mutate(false);
  };
  return {
    isShowClassUpdatePopup,
    on,
    off,
  };
}

export const on = () => {
  mutate(KEY, true);
};

export const off = () => {
  mutate(KEY, false);
};
