import React from 'react';
import './MainButton.scss';


const MainButton = () => {
  
  return (
    <div className='main-button'>
      <div className='main-vrware'>
        <button onClick={() => window.open('http://vrware.co.kr/default/', '_blank')}><p>VRWARE</p><p>바로가기</p></button>
      </div>
      <div className='main-school'>
      <button onClick={() => window.open('https://school.vrware.us/', '_blank')} ><p>School</p><p>바로가기</p></button>
      </div>
    </div>
  );
};

export default MainButton;
