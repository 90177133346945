import React from 'react';
import './MainBanner.scss';
import { MainButton } from '../MainButton';
import { MainBanners, MainBannerUS, MainBannerAR, BannerButtonAR, BannerButton } from './MainBanners';

type MainBannerProps = {
  option :string;
  on(): void;
};

const MainBanner = ({option, on} : MainBannerProps) => {
  
return (
  <div className='MainBanner' style={{ backgroundImage: `url(${'/images/main/Background.png'})` }}>
  <div className='school-banner-content'>
        <div className='test'>
            <div className='banner-text'>
              {option === 'en' ? <MainBannerUS/> : option === 'ar' ? <MainBannerAR/> : <MainBanners />}
                <div className='banner-text-title-last'></div>
                {option == 'ar' ? <BannerButtonAR onClick={on}/> : <BannerButton onClick={on}/> }
            </div>
            <div className='banner-text'>
            {option === '' ? <MainButton/> : option === 'ko' ? <MainButton/> : <div></div>}
            </div >
        </div>
  </div>
</div>
  );
};

export default MainBanner;