import React from 'react';
import './HeaderNotLogin.scss';
// import { useLocation } from 'react-router-dom';


type HeaderNotLoginProps = {
  selected : String;
};

const HeaderNotLogin = ({ selected } : HeaderNotLoginProps) => {
  // let location = useLocation();
  let loginUrl: string;

  if (process.env.NODE_ENV === 'development') {
    loginUrl = 'https://my.vrware.us'; // 나중에 바꾸삼
    // redirectUrl = 'http://localhost:3001' + location.pathname;
  } else if (process.env.NODE_ENV === 'production') {
    loginUrl = 'https://my.vrware.us';
    // redirectUrl = 'https://school.vrware.us' + location.pathname;
  }


const Check = () => {
  return (
    <ul className='HeaderNotLogin'>
      <li>
        <a href={`${loginUrl}/join?hl=${selected}`}>
          <button className='login-button'>회원가입</button>
        </a>
      </li>
      <li>
        <a href={`${loginUrl}/login?hl=${selected}`}>
          <button className='login-button'>로그인</button>
        </a>
      </li>
    </ul>
  );

};

const CheckUS = () => {
  return (
    <ul className='HeaderNotLogin'>
      <li>
        <a href={`${loginUrl}/join?hl=${selected}`}>
          <button className='login-button'>Sign Up</button>
        </a>
      </li>
      <li>
        <a href={`${loginUrl}/login?hl=${selected}`}>
          <button className='login-button'>Login</button>
        </a>
      </li>
    </ul>
  );

};

const CheckAR = () => {
  return (
    <ul className='HeaderNotLogin'>
      <li>
        <a href={`${loginUrl}/join?hl=en`}>
          <button className='login-button-ar'>تسجيل الدخول</button>
        </a>
      </li>
      <li>
        <a href={`${loginUrl}/login?hl=en`}>
          <button className='login-button-ar'>تسجيل الخروج</button>
        </a>
      </li>
    </ul>
  );

};



  return (
    <div>
    {
      selected === 'en'
      ? <CheckUS />
      : selected === 'ar' ? <CheckAR /> : <Check />
    }
    </div>
  );
};

export default HeaderNotLogin;
