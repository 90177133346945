import React from 'react';
import './MainBanner.scss';
type MainBannerProps = {
  onClick(): void;
};

export const MainBanners = () => {
  return (
    <div className='banner-text-title'>
        <img src={'/images/main/Background_Title.png'} alt='나만의 이야기를 창작하는 멀티미디어 VR BOOK' />
    </div>
    
  );
};

export const MainBannerUS = () => {
  return (
    <div className='banner-text-title'>
      <img src={'/images/main/Background_TitleUS.png'} alt='나만의 이야기를 창작하는 멀티미디어 VR BOOK' />
    </div>
  );
};


export const MainBannerAR = () => {
  return (
    <div className='banner-text-title'>
      <img src={'/images/main/Background_TitleAR.png'} alt='나만의 이야기를 창작하는 멀티미디어 VR BOOK' />
    </div>
  );
};

export const BannerButton = ({onClick} : MainBannerProps ) => {
  return (
    <div className='banner-text-title'>
      <button onClick={onClick}>Watch Now</button>
  </div>
  );
}

export const BannerButtonAR = ({onClick} : MainBannerProps ) => {
  return (
  <div className='banner-text-title'>
    <button onClick={onClick}>شاهد الآن</button>
  </div>
  );
}