import React from 'react';
import './SelectBox.scss';

const SelectBox = ({ label, options, handleChangeQuestionOption, ...rest }) => {
  const optionsList = options.map(options => (
    <option key={options.key} value={options.option} {...rest}>
      {options.option}
    </option>
  ));
  return (
    <div className='select'>
      <label>{label}</label>
      <select onChange={e => handleChangeQuestionOption(e.target.value)} {...rest}>
        {optionsList}
      </select>
      <div className='select__arrow' />
    </div>
  );
};

export default SelectBox;
