import React, { useState } from 'react';
import { Popup } from '../../common/Popup';
import { InputWithLabelBorderEffect } from '../../common/InputWithLabelBorderEffect';
import { SelectBox } from '../../common/SelectBox';
import './MyClassUpdatePopup.scss';
import { SchoolApi } from '../../../lib/api';
import useUpdatePopup, { off } from 'hooks/myClass/useUpdatePopup';
import { MyClassInfo } from 'types';

type MyClassUpdatePopupProps = {
  option : string;
  myClassDetail: MyClassInfo;
  loadMyClassDetail(): void;
};

const MyClassUpdatePopupKO = ({ option, myClassDetail, loadMyClassDetail }: MyClassUpdatePopupProps) => {
  const { isShowClassUpdatePopup } = useUpdatePopup();
  const [className, setClassName] = useState(myClassDetail.className);
  const [classGrade, setClassGrade] = useState(myClassDetail.classGrade);
  const [classExplain, setClassExplain] = useState(myClassDetail.classExplain);
  const [notifyClassName, setNotifyClassName] = useState('');
  const [notifyClassExplain, setNotifyClassExplain] = useState('');

  if (!myClassDetail) return null;
  if (!isShowClassUpdatePopup) return null;

  const onChangeClassName = (_className: string) => {
    setClassName(_className);

    if (_className.length === 0) {
      setNotifyClassName('학급 이름을 입력해 주세요.');

      return;
    } else if (_className.length > 0 && !/^[a-zA-Z0-9가-힣\s]{2,}$/.test(_className)) {
      setNotifyClassName('학급 이름을 정확히 입력해 주세요.');
      return;
    } else {
      setNotifyClassName('');
    }
  };

  const onChangeClassNameUS = (_className: string) => {
    setClassName(_className);

    if (_className.length === 0) {
      setNotifyClassName('학급 이름을 입력해 주세요.s');

      return;
    } else if (_className.length > 0 && !/^[a-zA-Z0-9가-힣\s]{2,}$/.test(_className)) {
      setNotifyClassName('학급 이름을 정확히 입력해 주세요.s');
      return;
    } else {
      setNotifyClassName('');
    }
  };

  const onChangeClassGrade = (_classGrade: string) => {
    setClassGrade(_classGrade);
  };

  const onChangeClassExplain = (classExplain: string) => {
    setClassExplain(classExplain);

    if (classExplain.length === 0) {
      setNotifyClassExplain('학급 설명을 최대 한 글자 이상 작성하여 주세요.');
      return;
    } else if (
      classExplain.length > 0 &&
      !/^[a-z|A-Z|0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!@#$%^&*-+=_.?/,|\s]{1,}$/.test(classExplain)
    ) {
      setNotifyClassExplain('학급 설명을 한 글자 이상 입력해 주세요.');
      return;
    } else {
      setNotifyClassExplain('');
    }
  };

  const onChangeClassExplainUS = (classExplain: string) => {
    setClassExplain(classExplain);

    if (classExplain.length === 0) {
      setNotifyClassExplain('학급 Explain을 최대 한 글자 이상 작성하여 주세요.');
      return;
    } else if (
      classExplain.length > 0 &&
      !/^[a-z|A-Z|0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!@#$%^&*-+=_.?/,|\s]{1,}$/.test(classExplain)
    ) {
      setNotifyClassExplain('학급 Explain을 한 글자 이상 입력해 주세요.');
      return;
    } else {
      setNotifyClassExplain('');
    }
  };

  const onClickClassUpdate = async () => {
    if (!className || !classExplain || !classGrade) {
      return alert('입력하지 않은 값들이 있는지 확인해주세요.');
    } else if (notifyClassName || notifyClassExplain) {
      return;
    }

    try {
      const res = await SchoolApi.patch(`/classes/${myClassDetail?.classId}`, {
        className,
        classExplain,
        classGrade,
      });
      if (res.data.status_code === 200) {
        off();
        loadMyClassDetail();
        return alert(res.data.alert_message);
      }
    } catch (error : any) {
      if (error.response.data.status_code >= 400) {
        return alert(error.response.data.alert_message);
      }
    }
  };


  const onClickClassUpdateUS = async () => {
    if (!className || !classExplain || !classGrade) {
      return alert('입력하지 않은 values 있는지 확인해주세요.');
    } else if (notifyClassName || notifyClassExplain) {
      return;
    }

    try {
      const res = await SchoolApi.patch(`/classes/${myClassDetail?.classId}`, {
        className,
        classExplain,
        classGrade,
      });
      if (res.data.status_code === 200) {
        off();
        loadMyClassDetail();
        return alert(res.data.alert_message);
      }
    } catch (error : any) {
      if (error.response.data.status_code >= 400) {
        return alert(error.response.data.alert_message);
      }
    }
  };

  const ClassUpdateButtonBox = () => {
    return (
      <div className='class-update-button-box'>
        <button className='class-update-button' onClick={onClickClassUpdate}>
          수정
        </button>
        <button className='class-update-cancel-button' onClick={off}>
          취소
        </button>
        </div>
    );
  }
  const ClassUpdateButtonBoxUS = () => {
    return (
      <div className='class-update-button-box'>
        <button className='class-update-button' onClick={onClickClassUpdateUS}>
          수정s
        </button>
        <button className='class-update-cancel-button' onClick={off}>
          취소s
        </button>
        </div>
    );
  }

  const ClassUpdateTitle = () => {
    return(
      <div className='class-update-title'>학급 수정</div>
    );
  }

  const ClassUpdateTitleUS = () => {
    return(
      <div className='class-update-title'>학급 수정</div>
    );
  }

  return (
    <Popup>
      <div className='MyClassUpdatePopup'>
        {option === 'en' ? <ClassUpdateTitleUS/> : <ClassUpdateTitle/>}
        <div className='class-update-info'>
          <div className='class-update-input-box'>
            {option === 'en' ? 
            <InputWithLabelBorderEffect
              label='학급 이름s'
              notify={notifyClassName}
              placeholder='학급 이름을 입력해주세요.s'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeClassNameUS(e.target.value)}
              defaultValue={myClassDetail.className}
            />
            :
             <InputWithLabelBorderEffect
              label='학급 이름'
              notify={notifyClassName}
              placeholder='학급 이름을 입력해주세요.'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeClassName(e.target.value)}
              defaultValue={myClassDetail.className}
            />
          }
          {option === 'en' ?
          <SelectBox
          label='학년s'
          options={gradeSelectOptionsUS}
          handleChangeQuestionOption={onChangeClassGrade}
          defaultValue={myClassDetail.classGrade}
        />
        :<SelectBox
        label='학년'
        options={gradeSelectOptions}
        handleChangeQuestionOption={onChangeClassGrade}
        defaultValue={myClassDetail.classGrade}
      />
        }

        {option === 'en' ?
        <InputWithLabelBorderEffect
        label='학급 소개s'
        notify={notifyClassExplain}
        placeholder='학급 소개를 한 글자 이상으로 입력해주세요.s'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeClassExplainUS(e.target.value)}
        defaultValue={myClassDetail.classExplain}
      />
      :<InputWithLabelBorderEffect
      label='학급 소개'
      notify={notifyClassExplain}
      placeholder='학급 소개를 한 글자 이상으로 입력해주세요'
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeClassExplain(e.target.value)}
      defaultValue={myClassDetail.classExplain}
    />
        }
           
          </div>
          {option === 'en' ? <ClassUpdateButtonBoxUS/> : <ClassUpdateButtonBox/>}
        </div>
      </div>
    </Popup>
  );
};



const gradeSelectOptions = [
  { key: '1', option: '초등학교' },
  { key: '2', option: '중학교' },
  { key: '3', option: '고등학교' },
  { key: '4', option: '기타' },
];
 const gradeSelectOptionsUS = [
  { key: '1', option: '초등학교' },
  { key: '2', option: 'middle School' },
  { key: '3', option: 'High School' },
  { key: '4', option: '기타' },
];
export default MyClassUpdatePopupKO;
