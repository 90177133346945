import React from 'react';
import './StudentItem.scss';
import { trash_can_stroke } from '../../../images/MyClass';
import { dateFormat } from 'lib/common/dateFormat';
import getEmail from 'lib/common/getEmail';
import { MyClassInfo, MyClassStudent } from 'types';
import { useHistory } from 'react-router-dom';
import { SchoolApi } from 'lib/api';

type StudentItemProps = {
  studentInfo: MyClassStudent;
  myClassDetail?: MyClassInfo;
};
const StudentItem = ({ studentInfo, myClassDetail }: StudentItemProps) => {
  const history = useHistory();

  const onClickStudentDelete = async (classCode: string | undefined, email: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('해당 학생을 학급에서 제외하시겠습니까?') === true) {
      try {
        const res = await SchoolApi.delete(`/classes/${classCode}/students/${email}`);
        if (res.data.status_code === 200) {
          alert(res.data.alert_message);
          history.go(0);
        }
      } catch (error : any) {
        if (error.response.data.status_code >= 400) {
          return alert(error.response.data.alert_message);
        }
      }
    }
  };

  return (
    <div className='StudentItem'>
      <div className='student-info-wrap'>
        <div className='student-title-wrap'>
          <p className='student-title'>{'이메일'}</p>
          <p className='student-title'>{'가입 날짜'}</p>
        </div>
        <div className='student-value-wrap'>
          <p className='student-value'>{studentInfo.studentEmail}</p>
          <p className='student-value'>{dateFormat(studentInfo.joinDate, 'yyyy-MM-dd')}</p>
        </div>
        {getEmail() === myClassDetail?.teacherEmail ? (
          <div className='student-option'>
            <img
              src={trash_can_stroke}
              alt='trash_can_stroke'
              onClick={() => onClickStudentDelete(myClassDetail?.classCode, studentInfo.studentEmail)}
            />
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default StudentItem;
