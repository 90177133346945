import React, { useEffect } from 'react';
import AOS from 'aos';
import { MainTemplate } from '../components/main/MainTemplate';
import { Header } from '../components/base/Header';
import { MainBanner } from '../components/main/MainBanner';
import { Footer } from '../components/base/Footer';
import { bgColorAtom, selectAtom } from 'lib/common/atoms';
import { useAtom } from 'jotai';
import useShowVideo from '../hooks/main/useShowVideo';
import { SchoolIntroVideo } from '../components/main/SchoolIntroVideo';
export const Main = () => {
  const { isShowVideo, on, off } = useShowVideo();

  const [, setBgColor] = useAtom(bgColorAtom);

  useEffect(() => {
    AOS.init({ duration: 1200 });
    return () => {
      AOS.refresh();
    };
  }, []);

  useEffect(() => {
    setBgColor('transparent');
  }, [setBgColor]);



  const [option, setOption] = useAtom(selectAtom);
  
  const onChangeOption = (e: { target: { value : string } }) => {
    setOption(e.target.value);
    console.log(e.target.value);
  };


  return (
    <MainTemplate header={<Header option={option} changeOption={onChangeOption} />} footer={<Footer option={option}/>}>
      <SchoolIntroVideo option={option} isShowVideo={isShowVideo} off={off} />
      <MainBanner option={option} on={on} />
    </MainTemplate>
  );
};
