import React, { useState } from "react";
import styled from "styled-components";
const DropDownContainer = styled("div")`
    position: absolute;
    top: 2.8em;
    right: 19em;
    background-repeat: no-repeat;
background-image: url(/images/header/button.png);
height: 32px;
width: 32px;
&:hover{
  cursor:pointer;
}
`;

const DropDownHeader = styled("div")`

padding: 0.4em 2em 0.4em 1em;
font-size: 1.3rem;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
    height: 140px;
    width: 120px;
    top: 30px;
    background-color: rgb(255, 255, 255);
    line-height: 20px;
    border-radius: 10px;
    color: rgb(115, 115, 115);
    font-size: 14px;
    font-weight: 500;
    position: relative;
    left: -39px;
    z-index: 100;
    text-align: center;

::before {
  border-color: rgb(255, 255, 255) transparent;
  border-style: solid;
  border-width: 0px 6px 8px 6.5px;
  content: "";
  display: block;
  left: 50px;
  position: absolute;
  top: -7px;
  width: 0px;
  z-index: 0;
}
`;


const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  padding: 3px 0;
  :hover{
    color: #FCC500
  }
`;


type SelectBoxProps = {
  getOption : any;
  props :{ option: any[]; }
}


const SelectBox = ( {getOption, props} : SelectBoxProps) => {


  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);



  const onOptionClicked = (value: any) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    getOption(value)
  };


  

  return (
      <DropDownContainer>
        <DropDownHeader  onClick={toggling} />
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {props.option.map(o => (
                <ListItem onClick={onOptionClicked(o.value)} key={o.value}>
                  {o.name}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
 
  );
}

export default SelectBox;