


import React from 'react';
import './HeaderMobileMenu.scss';

type HeaderMobileMenuProps = {
  isSession?: boolean;
  onClickLogout(): Promise<void>;
  onClickGoToPath(path: string): void;
};

export const HeaderMobileMenus = ({ isSession, onClickGoToPath, onClickLogout }: HeaderMobileMenuProps) => {
  let loginUrl;

  if (process.env.NODE_ENV === 'development') {
    loginUrl = 'http://localhost:3000';
    // redirectUrl = 'http://localhost:3001' + location.pathname;
  } else if (process.env.NODE_ENV === 'production') {
    loginUrl = 'https://my.vrware.us';
    // redirectUrl = 'https://school.vrware.us' + location.pathname;
  }

  return (
          <ul>
            <li onClick={() => onClickGoToPath('/intro')}>콘텐츠 소개</li>
            <li onClick={() => onClickGoToPath('/download')}>다운로드</li>
            <li onClick={() => (window.location.href = 'https://my.vrware.us/cs')}>고객센터</li>

            {!isSession ? (
              <div>
                <li>
                  <a href={`${loginUrl}/join`}>회원가입</a>
                </li>
                <li>
                  <a href={`${loginUrl}/login`}>로그인</a>
                </li>
              </div>
            ) : (
              <div>
                <li onClick={() => (window.location.href = 'https://my.vrware.us/mypage')}>마이페이지</li>
                <li onClick={onClickLogout}>로그아웃</li>
              </div>
            )}
          </ul>
  );
};


export const HeaderMobileMenusUS = ({ isSession, onClickGoToPath, onClickLogout }: HeaderMobileMenuProps) => {
  let loginUrl;

  if (process.env.NODE_ENV === 'development') {
    loginUrl = 'http://localhost:3000';
    // redirectUrl = 'http://localhost:3001' + location.pathname;
  } else if (process.env.NODE_ENV === 'production') {
    loginUrl = 'https://my.vrware.us';
    // redirectUrl = 'https://school.vrware.us' + location.pathname;
  }

  return (
          <ul>
            <li onClick={() => onClickGoToPath('/intro')}>Content 소개</li>
            <li onClick={() => onClickGoToPath('/download')}>다운로드</li>
            <li onClick={() => (window.location.href = 'https://my.vrware.us/cs')}>고객센터</li>

            {!isSession ? (
              <div>
                <li>
                  <a href={`${loginUrl}/join`}>회원가입</a>
                </li>
                <li>
                  <a href={`${loginUrl}/login`}>Login</a>
                </li>
              </div>
            ) : (
              <div>
                <li onClick={() => (window.location.href = 'https://my.vrware.us/mypage')}>마이페이지</li>
                <li onClick={onClickLogout}>Logout</li>
              </div>
            )}
          </ul>
  );
};