import useSWR, { mutate } from 'swr';

const KEY = 'classSelectPopup';

export default function useSelectPopup() {
  const { data: isShowSelectOptionPopup = false, mutate } = useSWR<boolean>(KEY);

  const on = () => {
    mutate(true);
  };

  const off = () => {
    mutate(false);
  };

  return { isShowSelectOptionPopup, on, off };
}

export const on = () => {
  mutate(KEY, true);
};

export const off = () => {
  mutate(KEY, false);
};
