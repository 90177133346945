import React from 'react';
import './Footer.scss';
import { blog, facebook, twitter, youtube } from 'images/Footer';


export const FooterCenterTop = () => {
  return (

        <div className='footer-center-top'>
        <a href='https://my.vrware.us/policy/privacy'>
          <p>개인정보처리방침이용약관</p>
        </a>
        <p className='footer-center-p'>|</p>
        <a href='https://my.vrware.us/policy/service'>
          <p>이용약관</p>
        </a>
      </div>
  );
}

export const FooterCenterTopUS = () => {
  return (

        <div className='footer-center-top'>
        <a href='https://my.vrware.us/policy/privacy?hl=en'>
          <p>Privacy Policy</p>
        </a>
        <p className='footer-center-p'>|</p>
        <a href='https://my.vrware.us/policy/service?hl=en'>
          <p>Terms of Use</p>
        </a>
      </div>
  );
}




export const FooterCenterBottom = () => {
  return(
    <div className='footer-center-bottom'>
    <div>
      <p>(주)글로브포인트 사업자등록번호 : 107-87-72034</p>
      <p>대표이사 : 조상용</p>
    </div>
    <p>주소 : 경기도 고양시 덕양구 삼원로 83, 1111호 (원흥동, 광양프런티어밸리 6차)</p>
    <div>
      <p>전화:031-911-0601</p>
      <a href='mailto:gpsales@globepoint.co.kr'>이메일 : gpsales@globepoint.co.kr</a>
    </div>
    <p className='copyrights'>Copyright © 2023 Globepoint Inc. All rights reserved.</p>
  </div>
  );
}

export const FooterCenterBottomUS = () => {
  return(
    <div className='footer-center-bottom'>
    <div>
      <p>GLOBEPOINT Inc. Business registration number: 107-87-72034</p>
      <p>CEO : Jo Sang-yong</p>
    </div>
    <p>11F #1111, Gwangyang Frontier Valley 6, 83 Samwon-ro, Deokyang-gu,</p>
    <p>Goyang-si, Gyeonggi-do, Republic of Korea (Zip 10550)</p>
    <div>
      <p>Tel: +82 31 911 0601</p>
      <a href='mailto:gpsales@globepoint.co.kr'>E-mail: gpsales@globepoint.co.kr</a>
    </div>
    <p className='copyrights'>Copyright © 2023 Globepoint Inc. All rights reserved.</p>
  </div>
  );
}



export const FooterSNS = () => {
  return (
    <ul>
      <li>
        <a href='https://www.youtube.com/channel/UCZGwK7Ac87irDmPZfoLPvlg' target='_sub'>
          <img src={youtube} alt='youtube' />
        </a>
      </li>
      <li>
        <a href='https://www.facebook.com/GlobePoint.Inc/' target='_sub'>
          <img src={facebook} alt='facebook' />
        </a>
      </li>
      <li>
        <a href='https://twitter.com/GlobepointK' target='_sub'>
          <img src={twitter} alt='twitter' />
        </a>
      </li>
      <li>
        <a href='http://blog.vrware.co.kr/' target='_sub'>
          <img src={blog} alt='blog' />
        </a>
      </li>
    </ul>
  );
};

export const FooterRight = () => {
  return(
    <ul className='footer-right'>
      <li className='footer-cs'>
        <p>고객센터</p>
      </li>
      <li className='footer-company-ph'>
        <p>031-911-0601</p>
      </li>
      <li className='footer-company-wh'>
        <p>평일 10:00~18:00 (점심시간 12:30~13:30)</p>
        <p>국/공휴일 휴무</p>
      </li>
      <li className='footer-company-sns'>
        <FooterSNS />
      </li>
  </ul>
  );
}

export const FooterRightUS = () => {
  return(
    <ul className='footer-right'>
      <li className='footer-cs'>
        <p>customer center</p>
      </li>
      <li className='footer-company-ph'>
        <p>+82 911 0601</p>
      </li>
      <li className='footer-company-wh'>
        <p>Weekdays 10:00~18:00 (Lunch break 12:30~13:30)</p>
        <p>Closed on national/holidays</p>
      </li>
      <li className='footer-company-sns'>
        <FooterSNS />
      </li>
  </ul>
  );
}


export const FooterBottomSNS = () => {
  return (
    <div className='footer-bottom-sns'>
      <ul>
        <li>
          <a href='https://www.youtube.com/channel/UCZGwK7Ac87irDmPZfoLPvlg' target='_sub'>
            <img src={youtube} alt='youtube' />
          </a>
        </li>
        <li>
          <a href='https://www.facebook.com/GlobePoint.Inc/' target='_sub'>
            <img src={facebook} alt='facebook' />
          </a>
        </li>
        <li>
          <a href='https://twitter.com/GlobepointK' target='_sub'>
            <img src={twitter} alt='twitter' />
          </a>
        </li>
        <li>
          <a href='http://blog.vrware.co.kr/' target='_sub'>
            <img src={blog} alt='blog' />
          </a>
        </li>
      </ul>
    </div>
  );
};

