import useSWR, { mutate } from 'swr';

const KEY = 'classJoinPopup';

export default function useJoinPopup() {
  const { data: isShowJoinPopup = false, mutate } = useSWR<boolean>(KEY);

  const on = () => mutate(true);

  const off = () => mutate(false);

  return { isShowJoinPopup, on, off };
}

export const on = () => mutate(KEY, true);

export const off = () => mutate(KEY, false);
