import React from 'react';
import './SchoolDownloadSpecPC.scss';

type Txt = {
  headLineTxt : string;
}

type Txt2 = {
  partTxt: string;
  specTxt: string;
};



export const DownloadHeadLinePC = ({headLineTxt} : Txt) => {
  return(
    <div className='download-headline-pc'>
      <p>{headLineTxt}</p>
  </div>
  );
}


export const DownloadSpecHeadPC = () => {
  return (
    <thead>
      <tr className='download-spec-head-pc'>
        <th className='download-col-first-pcs'>구분</th>
        <th className='download-col-rest-pcs'>권장사양</th>
      </tr>
    </thead>
  );
};

export const DownloadSpecHeadARPC = () => {
  return (
    <thead>
      <tr className='download-spec-head-pc'>
        <th className='download-col-first-pcs'>تصنيف</th>
        <th className='download-col-rest-pcs'>المواصفات الموصى بها</th>
      </tr>
    </thead>
  );
};

 export const DownloadSpecHeadUSPC = () => {
  return (
    <thead>
      <tr className='download-spec-head-pc'>
        <th className='download-col-first-pcs'>Sortation</th>
        <th className='download-col-rest-pcs'>Recommended</th>
      </tr>
    </thead>
  );
};







export const DownloadSpecTablePC = ({ children }: { children: React.ReactNode }) => {
  return <table className='download-spec-table-pc'>{children}</table>;
};

export const DownloadSpecCPUPC = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info-pc'>
      <td className='download-col-first-pc'>{partTxt}</td>
      <td className='download-col-rest-pc'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecRAMPC = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info-pc'>
      <td className='download-col-first-pc'>{partTxt}</td>
      <td className='download-col-rest-pc'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecVGAPC = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info-pc'>
      <td className='download-col-first-pc'>{partTxt}</td>
      <td className='download-col-rest-pc'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecHDDPC = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info-pc'>
      <td className='download-col-first-pc'>{partTxt}</td>
      <td className='download-col-rest-pc'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecOSPC = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info-pc'>
      <td className='download-col-first-pc'>{partTxt}</td>
      <td className='download-col-rest-pc'>{specTxt}</td>
    </tr>
  );
};