import React, { useCallback, useState, useEffect } from 'react';
import { MyClassTemplate } from 'components/myClass/MyClassTemplate';
import { Header } from 'components/base/Header';
import { Footer } from 'components/base/Footer';
import { Banner } from 'components/common/Banner';
import { class_illust } from 'images/MyClass';
import { MyClassDetailTop, MyClassDetailTopUS } from 'components/myClassDetail/MyClassDetailTop';
import { StudentList,StudentListUS } from 'components/myClassDetail/StudentList';
import { MyClassUpdatePopup } from 'components/myClassDetail/MyClassUpdatePopup';
import { useHistory, useParams } from 'react-router-dom';
import useMember from 'hooks/useMember';
import { SchoolApi } from 'lib/api';
import { MyClassStudent, MyClassInfo } from 'types';
import { useAtom } from 'jotai';
import { selectAtom } from '../lib/common/atoms';

const MyClassDetailPage = () => {
  const history = useHistory();
  const { email } = useMember();
  const classCode = useParams();
  const [myClassDetail, setMyClassDetail] = useState<MyClassInfo>();
  const [myClassStudents, setMyClassStudents] = useState<MyClassStudent[]>([]);

  const loadMyClassDetail = useCallback(async () => {
    if (!email || !classCode) return;

    try {
      const res = await SchoolApi.get(`/classes?email=${email}&classCode=${classCode}`);

      if (res.data.status_code !== 200 || res.data.data.classList.length === 0) {
        return history.push('/myclass');
      }

      setMyClassDetail(res.data.data.classList[0]);
      setMyClassStudents(res.data.data.classList[0].students);
    } catch (error : any) {
      if (error.response.data.status_code >= 400) {
        setMyClassDetail(undefined);
        setMyClassStudents([]);
        if (error.response.data.status_message === "class doesn't exist") {
          return history.push('/myclass');
        }
      }
    }
  }, [classCode, email, history]);

  useEffect(() => {
    loadMyClassDetail();
  }, [loadMyClassDetail]);

  const [option, setOption] = useAtom(selectAtom);
  
  const onChangeOption = (e: { target: { value : string } }) => {
    setOption(e.target.value);
    console.log(e.target.value);
  };


  return (
    <MyClassTemplate header={<Header option={option} changeOption={onChangeOption}/>} footer={<Footer option={option}/>}>
      {option === 'en' ? <Banner bannerText='My Class' illust={class_illust} /> 
      :<Banner bannerText='내 학급' illust={class_illust} />}
      <div className='container'>
        {option === 'en' ? <MyClassDetailTopUS email={email} myClassStudents={myClassStudents} myClassDetail={myClassDetail} />
        : <MyClassDetailTop email={email} myClassStudents={myClassStudents} myClassDetail={myClassDetail} />}
        {option === 'en' ?  <StudentListUS myClassStudents={myClassStudents} myClassDetail={myClassDetail} />
        :  <StudentList myClassStudents={myClassStudents} myClassDetail={myClassDetail} />}
        
        : {myClassDetail && <MyClassUpdatePopup option={option} myClassDetail={myClassDetail} loadMyClassDetail={loadMyClassDetail} />} 
      </div>
    </MyClassTemplate>
  );
};

export default MyClassDetailPage;
