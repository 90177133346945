import React, { useState, useCallback, useEffect } from 'react';
import { MyClassTemplate } from '../components/myClass/MyClassTemplate';
import Banner from '../components/common/Banner/Banner';
import { class_illust } from '../images/MyClass';
import { MyClassList, MyClassListAR, MyClassListUS } from '../components/myClass/MyClassList';
import { Header } from '../components/base/Header';
import { Footer } from '../components/base/Footer';
import { CreateOrJoinPopup, CreateOrJoinPopupUS } from '../components/myClass/CreateOrJoinPopup';
import { ClassCreatePopup, ClassCreatePopupUS } from '../components/myClass/ClassCreatePopup';
import { ClassJoinPopup, ClassJoinPopupUS } from '../components/myClass/ClassJoinPopup';
import useSession from '../hooks/useSession';
import useMember from 'hooks/useMember';
import { MyClassInfo } from 'types';
import { SchoolApi } from 'lib/api';
import { useAtom } from 'jotai';
import { selectAtom } from '../lib/common/atoms';


const MyClassPage = () => {
  const { isSession } = useSession();
  const { email } = useMember();
  const [myClassList, setMyClassList] = useState<MyClassInfo[]>([]);

  const loadMyClassList = useCallback(async () => {
    if (!email) return;

    try {
      const res = await SchoolApi.get(`/classes?email=${email}`);
      if (res.data.status_code === 200) {
        setMyClassList(res.data.data.classList);
      }
    } catch (error : any) {
      if (error.response.data.status_code >= 400) {
        setMyClassList([]);
      }
    }
  }, [email]);

  useEffect(() => {
    loadMyClassList();
  }, [loadMyClassList]);

  const [option, setOption] = useAtom(selectAtom);
  
  const onChangeOption = (e: { target: { value : string } }) => {
    setOption(e.target.value);
    console.log(e.target.value);
  };


  return (
    <MyClassTemplate header={<Header option={option} changeOption={onChangeOption}/>} footer={<Footer option={option}/>}>
      {option ==='en' ? <Banner bannerText='MY CLASS' illust={class_illust} /> : option === 'ar' ? <Banner bannerText='صفي' illust={class_illust} /> : <Banner bannerText='내 학급' illust={class_illust} />}
      <div className='container'>
        {option === 'en' ? 
        <MyClassListUS email={email} isSession={isSession} myClassList={myClassList} loadMyClassList={loadMyClassList} />
        : option === 'ar' ? <MyClassListAR email={email} isSession={isSession} myClassList={myClassList} loadMyClassList={loadMyClassList} /> 
        : <MyClassList email={email} isSession={isSession} myClassList={myClassList} loadMyClassList={loadMyClassList} />
      }
        {/* TODO: 팝업관련 hooks 구현 다시 해야함 */}
        {option === 'en' ? <CreateOrJoinPopupUS /> : <CreateOrJoinPopup />}
        {option === 'en' ? <ClassCreatePopupUS email={email} loadMyClassList={loadMyClassList} /> 
        : <ClassCreatePopup email={email} loadMyClassList={loadMyClassList} />}
        {option === 'en' ? <ClassJoinPopupUS email={email} loadMyClassList={loadMyClassList} />
        : <ClassJoinPopup email={email} loadMyClassList={loadMyClassList} />}
      </div>
    </MyClassTemplate>
  );
};

export default MyClassPage;
