import React from 'react';
import './SchoolDownload.scss';
import useSession from '../../../hooks/useSession';

type SchoolDownloadProps = {
  option : string;
}

const SchoolDownloadPC = ({option} : SchoolDownloadProps) => {
  const { isSession } = useSession();

  const onClickDownload = (downloadUrl: string): void => {
    // if (!isSession) {
    //   //비로그인 상태라면
    //   alert('로그인 후 다운로드 가능합니다.');
    //   window.location.href = 'https://my.vrware.us/login';
    // } else if (isSession) {
    //   //로그인 상태라면
      window.location.href = downloadUrl;
    // }
  };
  
   const onClickDownloadUS = (downloadUrl: string): void => {
    // if (!isSession) {
    //   //비로그인 상태라면
    //   alert('login after download can.');
    //   window.location.href = 'https://my.vrware.us/login';
    // } else if (isSession) {
    //   //로그인 상태라면
      window.location.href = downloadUrl;
    // }
  };


  const PCDownload = () => {
    return(
      <button
      onClick={() =>
        onClickDownload(
          'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_6d8329f76f1b4b0e9f2f872eb95e079e/programs/windows/VRWARE%20StoryBuilder.exe',
        )
      }
    >
      PC 버전 다운로드
      <img src={'/images/download/Icon_1.png'} alt='download' />
    </button>
    );
  }

const PCDownloadUS = () => {
    return(
      <button
      onClick={() =>
        onClickDownloadUS(
          'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_6d8329f76f1b4b0e9f2f872eb95e079e/programs/windows/VRWARE%20StoryBuilder.exe',
        )
      }
    >
      PC Download
      <img src={'/images/download/Icon_1.png'} alt='download' />
    </button>
    );
  }


  const PCDownloadAR = () => {
    return(
      <button
      onClick={() =>
        onClickDownloadUS(
          'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_6d8329f76f1b4b0e9f2f872eb95e079e/programs/windows/VRWARE%20StoryBuilder.exe',
        )
      }
    >
      تحميل نسخة الكمبيوتر
      <img src={'/images/download/Icon_1.png'} alt='download' />
    </button>
    );
  }



  return(
    <div className='school-download-pc-button'>
     {option === 'en' ? <PCDownloadUS/> : option === 'ar' ? <PCDownloadAR/> : <PCDownload/> }
     {/* {option === 'en' ?  <p className='school-download-pc-label'>*Download for Windows PC</p> : option === 'ar' ?  
     <p className='school-download-pc-label'>*Windows تحميل لجهاز الكمبيوتر بنظام</p>
     : <p className='school-download-pc-label'>*Windows PC용 다운로드</p> } */}
   </div>
  );

}

  export default SchoolDownloadPC;
  
