import React from 'react';
import './EduCaseTemplate.scss';

type EduCaseTemplateProps = {
  header?: JSX.Element;
  children: React.ReactNode;
  footer?: JSX.Element;
};

const EduCaseTemplate = ({ header, children, footer }: EduCaseTemplateProps) => {
  return (
    <div className='EduCaseTemplate'>
      {header}
      <main>{children}</main>
      {footer}
    </div>
  );
};

export default EduCaseTemplate;
