import React from 'react';
import './SchoolDownload.scss';
import SchoolDownloadPC from './SchoolDownloadPC';
import  { DownloadHeadLineUS, SchoolDownloadHmdUS, SchoolDownloadHmdAR,
   DownloadHeadLine,SchoolDownloadHmd, DownloadHeadLineAR,
   SchoolDownloadIOS, SchoolDownloadIOSUS, SchoolDownloadIOSAR 
} from './SchoolDownloads';

type SchoolDownloadProps = {
  option : string;
}
const SchoolDownload = ({option} : SchoolDownloadProps ) => {

  return (
    <div className='SchoolDownload'>
      {option === 'en' ? <DownloadHeadLineUS/> : option === 'ar' ? <DownloadHeadLineAR/> : <DownloadHeadLine/>}
      <div className='school-download-wrap'>
        <div className='school-download-pc'>
          <SchoolDownloadPC option={option}/>
        </div>
        {option === 'en' ? <SchoolDownloadHmdUS/> : option === 'ar' ? <SchoolDownloadHmdAR/> : <SchoolDownloadHmd/>}
        {option === 'en' ?  <SchoolDownloadIOSUS/> : option === 'ar' ? <SchoolDownloadIOSAR/> : <SchoolDownloadIOS/>}
      </div> 
    </div>
  );
};

export default SchoolDownload;
