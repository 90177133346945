import React from 'react';
import './MyClassDetailTop.scss';
import { option_white, teacher, student, code } from '../../../images/MyClass';
import { on } from 'hooks/myClass/useUpdatePopup';
import { MyClassInfo, MyClassStudent } from 'types';

type MyClassDetailTopProps = {
  email?: string;
  myClassDetail?: MyClassInfo;
  myClassStudents: MyClassStudent[];
};

const MyClassDetailTop = ({ email, myClassDetail, myClassStudents }: MyClassDetailTopProps) => {
  return (
    <div className='MyClassDetailTop'>
      <div className='class-info'>
        <div className='class-info-header-box'>
          <div className='class-info-header'>
            <label className='class-name'>{myClassDetail?.className}</label>
            <label className='class-grade'>{myClassDetail?.classGrade}</label>
            {email === myClassDetail?.teacherEmail ? (
              <div className='class-option' onClick={on}>
                <img src={option_white} alt='class_option' />
              </div>
            ) : null}
          </div>
        </div>
        <div className='class-detail-info'>
          <div className='class-explain'>
            <span className='explain-quotes'>‘‘</span>
            {myClassDetail?.classExplain}
            <span className='explain-quotes'>’’</span>
          </div>
          <hr className='class-info-stroke' />
          <div className='class-info-bottom'>
            <div className='class-teacher'>
              <img src={teacher} alt='teacher' />
              <span>교사 {myClassDetail?.teacherEmail}</span>
            </div>
            <div className='class-students'>
              <img src={student} alt='student' />
              <span>학생 {myClassStudents.length}명</span>
            </div>
            <div className='class-code'>
              <img src={code} alt='code' />
              <span>학급 코드 {myClassDetail?.classCode}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyClassDetailTop;
