import React from 'react';
import './SchoolDownloadSpec.scss';

type Txt = {
  headLineTxt : string;
}

type Txt2 = {
  partTxt: string;
  specTxt: string;
};



export const DownloadHeadLine = ({headLineTxt} : Txt) => {
  return(
    <div className='download-headline'>
      <p>{headLineTxt}</p>
  </div>
  );
}


export const DownloadSpecHead = () => {
  return (
    <thead>
      <tr className='download-spec-head'>
        <th className='download-col-first-hmds'>구분</th>
        <th className='download-col-rest-hmds'>권장사양</th>
      </tr>
    </thead>
  );
};

 export const DownloadSpecHeadUS = () => {
  return (
    <thead>
      <tr className='download-spec-head'>
        <th className='download-col-first-hmds'>Sortation</th>
        <th className='download-col-rest-hmds'>Recommended</th>
      </tr>
    </thead>
  );
};


export const DownloadSpecHeadAR = () => {
  return (
    <thead>
      <tr className='download-spec-head'>
        <th className='download-col-first-hmds'>تصنيف</th>
        <th className='download-col-rest-hmds'>المواصفات الموصى بها</th>
      </tr>
    </thead>
  );
};


export const DownloadSpecTable = ({ children }: { children: React.ReactNode }) => {
  return <table className='download-spec-table'>{children}</table>;
};

export const DownloadSpecCPU = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>{partTxt}</td>
      <td className='download-col-rest'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecRAM = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>{partTxt}</td>
      <td className='download-col-rest'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecVGA = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>{partTxt}</td>
      <td className='download-col-rest'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecHDD = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>{partTxt}</td>
      <td className='download-col-rest'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecOS = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>{partTxt}</td>
      <td className='download-col-rest'>{specTxt}</td>
    </tr>
  );
};




export const DownloadSpecTablePC = ({ children }: { children: React.ReactNode }) => {
  return <table className='download-spec-table'>{children}</table>;
};

export const DownloadSpecCPUPC = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>{partTxt}</td>
      <td className='download-col-rest'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecRAMPC = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>{partTxt}</td>
      <td className='download-col-rest'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecVGAPC = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>{partTxt}</td>
      <td className='download-col-rest'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecHDDPC = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>{partTxt}</td>
      <td className='download-col-rest'>{specTxt}</td>
    </tr>
  );
};

 export const DownloadSpecOSPC = ({ partTxt, specTxt }: Txt2) => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>{partTxt}</td>
      <td className='download-col-rest'>{specTxt}</td>
    </tr>
  );
};