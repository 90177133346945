import React from 'react';
import './BannerDownload.scss';

type BannerProps = {
  bannerText: string;
  centerNode?: JSX.Element;
  illust: string;
  bgColor?: string;
};

const Banner = ({ bannerText, centerNode, illust, bgColor }: BannerProps) => {
  return (
    <div className='Banner' style={{ backgroundColor: bgColor }}>
      <div className='banner-wrap'>
        <div className='banner-text'>{bannerText}</div>
        <div className='banner-img-download'>
          <img src={illust} alt='illust' />
        </div>
        {centerNode}
      </div>
    </div>
  );
};

export default Banner;
