import React from 'react';
import { Link } from 'react-router-dom';
import { trash_can_white, teacher, class_grade, student, code } from '../../../images/MyClass';
import './MyClassItem.scss';
import { MyClassInfo } from 'types';

type MyClassItemProps = {
  classInfo: MyClassInfo;
  email?: string;
  onClickClassDelete(classId: string): Promise<void>;
};

const MyClassItem = ({ classInfo, email, onClickClassDelete }: MyClassItemProps) => {
  return (
    <div className='MyClassItem'>
      <div className='class-header'>
        <div className='class-name'>{classInfo.className}</div>
        {email === classInfo.teacherEmail && (
          <div className='class-option'>
            <img src={trash_can_white} alt='trash_can_white' onClick={() => onClickClassDelete(classInfo.classId)} />
          </div>
        )}
      </div>
      <div className='class-info'>
        <div className='class-info-1'>
          <div className='class-teacher'>
            <img src={teacher} alt='teacher' />
            교사 {classInfo.teacherEmail}
          </div>
          <div className='class-grade'>
            <img src={class_grade} alt='grade' />
            {classInfo.classGrade}
          </div>
        </div>
        <div className='class-info-2'>
          <div className='class-students'>
            <img src={student} alt='student' />
            학생 {classInfo.students.length}명
          </div>
          <div className='class-code'>
            <img src={code} alt='code' />
            학급 코드 {classInfo.classCode}
          </div>
        </div>
      </div>
      <div className='class-enter-button'>
        <Link to={`/myclass/${classInfo.classCode}`}>
          <button>학급 들어가기</button>
        </Link>
      </div>
    </div>
  );
};

export default MyClassItem;
