import React from 'react';
import './StudentList.scss';
import { StudentItem } from '../StudentItem';
import { NoStudentsUS } from '../NoStudents';
import { MyClassStudent, MyClassInfo } from 'types';

type StudentListProps = {
  myClassStudents: MyClassStudent[];
  myClassDetail?: MyClassInfo;
};

const StudentListsUS = ({ myClassStudents, myClassDetail }: StudentListProps) => {
  return (
    <React.Fragment>
      <div className='class-list-box'>{'List of students'}</div>
      <div className='StudentList'>
        {myClassStudents.map((studentInfo) => (
          <StudentItem key={studentInfo.studentEmail} studentInfo={studentInfo} myClassDetail={myClassDetail} />
        ))}
      </div>
      {myClassStudents.length === 0 && <NoStudentsUS />}
    </React.Fragment>
  );
};

export default StudentListsUS;
