
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './HeaderMobileMenu.scss';
import { HeaderMobileMenus,HeaderMobileMenusUS } from './HeaderMobileMenus';

type HeaderMobileMenuProps = {
  option : string;
  isSession?: boolean;
  onClickLogout(): Promise<void>;
  onClickGoToPath(path: string): void;
};

const HeaderMobileMenu = ({ option, isSession, onClickGoToPath, onClickLogout }: HeaderMobileMenuProps) => {
  const location = useLocation();
  const { pathname } = location;

  let loginUrl : string;

  if (process.env.NODE_ENV === 'development') {
    loginUrl = 'http://localhost:3000';
    // redirectUrl = 'http://localhost:3001' + location.pathname;
  } else if (process.env.NODE_ENV === 'production') {
    loginUrl = 'https://my.vrware.us';
    // redirectUrl = 'https://school.vrware.us' + location.pathname;
  }

  useEffect(() => {
    function onClickToggle() {
      document.querySelector('.menu-trigger')?.classList.toggle('icon-active');
      document.querySelector('.links')?.classList.toggle('toggle');
      document.querySelector('.cotton')?.classList.toggle('cotton-toggle');

      if (pathname === '/') {
        document.querySelector('.headroom-wrapper')?.classList.toggle('headroom-transparent');
      }
    }

    document.getElementById('menu-trigger')?.addEventListener('click', onClickToggle);
    document.getElementById('cotton')?.addEventListener('click', onClickToggle);

    return () => {
      document.getElementById('menu-trigger')?.removeEventListener('click', onClickToggle);
      document.getElementById('cotton')?.removeEventListener('click', onClickToggle);
    };
  }, [pathname]);

  return (
    <div className='HeaderMobileMenu'>
      <div className='wrapper'>
        <div className='menu-trigger' id='menu-trigger'>
          <span />
          <span />
          <span />
          <span />
        </div>
        <div className='links'>
            {option === 'en' ? <HeaderMobileMenusUS onClickGoToPath={onClickGoToPath} isSession={isSession} onClickLogout={onClickLogout} /> : <HeaderMobileMenus onClickGoToPath={onClickGoToPath} isSession={isSession} onClickLogout={onClickLogout}/>}
          <div className='cotton' id='cotton' />
        </div>
      </div>
    </div>
  );
};

export default HeaderMobileMenu;