import useSWR, { mutate } from 'swr';

const KEY = 'classCreatePopup';

export default function useCreatePopup() {
  const { data: isShowCreatePopup, mutate } = useSWR<boolean>(KEY);

  const on = () => mutate(true);

  const off = () => mutate(false);

  return { isShowCreatePopup, on, off };
}

export const on = () => mutate(KEY, true);

export const off = () => mutate(KEY, false);
