import React from 'react';
import './IntroBottom.scss';


export const IntroPackageWriting = () => {
  return (
    <div className='intro-writing'>
      <p>VRWARE Edu StoryBuilder 패키지</p>
      <p className='middle'>1년 사용 라이선스 + 조립형 카드보드</p>
      <p className='bottom'>구성품 : 카드보드(도안+부픔+설명서), 라이선스 코드</p>
    </div>
  );
}

export const IntroPackageWritingUS = () => {
  return (
    <div className='intro-writing'>
      <p className='top-us'>VRWARE Edu StoryBuilder Package</p>
      <p className='middle-us'>1 Year License + DIY Cardboard</p>
      <p className='bottom-us'>The package includes a license to useVRWARE Edu StoryBuilder</p>
      <p className='bottom-us'>for one year and a Do-It-Yourself Cardboard.</p>
    </div>
  );
}

export const IntroPackageWritingAR = () => {
  return (
    <div className='intro-writing'>
      <p className='top-us'>VRWARE Edu StoryBuilder  حزمة ل</p>
      <p className='middle-us'>رخصة لمدة سنة + DIY نظارات ورقية</p>
      <p className='bottom-us'>VRWARE Edu Storybuilder تتضمن هذه الحزمة ترخيصًا لاستخدام برنامج</p>
      <p className='bottom-us'>الورقية DIY ونظارات.</p>
    </div>
  );
}

export const IntroVideoWriting = () => {
  return (
    <div className='intro-writing'>
        <p>조립형 카드보드를 이용해</p>
        <p>내가 만든 전자책을 VR로 체험할 수 있습니다.</p>
    </div>
  );
}

export const IntroVideoWritingUS = () => {
  return (
    <div className='intro-writing-us'>
        <p>You can experience the e-book you created</p>
        <p>in VR using assembled cardboard.</p>
    </div>
  );
}

export const IntroVideoWritingAR = () => {
  return (
    <div className='intro-writing-us'>
        <p>تجربة الكتاب الإلكتروني الذي قمت بصنعه في</p>
        <p>الواقع الافتراضي باستخدام نظارات الورق</p>
    </div>
  );
}

export const IntroVideoButton = () => {
  return (
    <div className='intro-button'>
      <button onClick={() => window.open('https://www.youtube.com/watch?v=ZfyE3tkxIJI', '_blank')} >만들기 영상 보러가기</button>
  </div>
  );
}

export const IntroVideoButtonUS = () => {
  return (
    <div className='intro-button-us'>
      <button onClick={() => window.open('https://www.youtube.com/watch?v=ZfyE3tkxIJI', '_blank')} >Watch the making video</button>
   </div>
  );
}

export const IntroVideoButtonAR = () => {
  return (
    <div className='intro-button-us'>
      <button onClick={() => window.open('https://www.youtube.com/watch?v=ZfyE3tkxIJI', '_blank')} >عرض فيديو الإنشاء</button>
   </div>
  );
}

export const IntroDevicesWritingUS = () => {
  return (
    <div className='intro-writing-us'>
      <p>VRWARE Edu StoryBuilder Compatible Devices</p>
  </div>
  );
}

export const IntroDevicesWritingAR = () => {
  return (
    <div className='intro-writing-us'>
      <p>VRWARE Edu StoryBuilder الأجهزة المتوافقة مع</p>
  </div>
  );
}


export const IntroDevicesWriting = () => {
  return (
    <div className='intro-writing'>
      <p>VRWARE Edu StoryBuilder 연동 기기</p>
  </div>
  );
}
