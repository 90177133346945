import React from 'react';
import './NoStudents.scss';
import { no_billing } from '../../../images/MyPage';

const NoStudentsUS = () => {
  return (
    <div className='NoStudents'>
      <img src={no_billing} alt='no_students' />
      <p>No students have participated.</p>
      <p>You can join the class by entering the class code.</p>
      {/* <Link to='/pricing'>
        <button>초대하기</button>
      </Link> */}
    </div>
  );
};

export default NoStudentsUS;
