import React from 'react';
import './IntroMiddle.scss';
import { IntroContentImgBoxOne, IntroContentImgBoxTwo, IntroContentImgBoxThree,IntroContentImgBoxFour,IntroContentImgBoxFive, IntroContentImgBoxSix, IntroContentImgBoxSeven,
  IntroContentImgBoxEight,IntroContentImgBoxNine,
  IntroContentImgBoxOneAR, IntroContentImgBoxTwoAR, IntroContentImgBoxThreeAR,IntroContentImgBoxFourAR,IntroContentImgBoxFiveAR, IntroContentImgBoxSixAR, IntroContentImgBoxSevenAR,
  IntroContentImgBoxEightAR,IntroContentImgBoxNineAR,
  IntroContentImgBoxOneUS, IntroContentImgBoxTwoUS, IntroContentImgBoxThreeUS,IntroContentImgBoxFourUS,IntroContentImgBoxFiveUS, IntroContentImgBoxSixUS, IntroContentImgBoxSevenUS,
  IntroContentImgBoxEightUS,IntroContentImgBoxNineUS
} from './IntroMiddles';

type IntroMiddleProps = {
  option : any;
}

export const IntroMiddle = ( option : IntroMiddleProps) => {
  return (
    <div className='IntroMiddle'>
      <div className='intro-middle-contents-wrap'>
        <div className='intro-content-list'>
          {option.option === 'en' ? <IntroContentImgBoxOneUS/> : option.option === 'ar' ?  <IntroContentImgBoxOneAR/> : <IntroContentImgBoxOne/>}
          {option.option === 'en' ? <IntroContentImgBoxTwoUS/> : option.option === 'ar' ?  <IntroContentImgBoxTwoAR/> : <IntroContentImgBoxTwo/>}
          {option.option === 'en' ? <IntroContentImgBoxThreeUS/> : option.option === 'ar' ?  <IntroContentImgBoxThreeAR/> : <IntroContentImgBoxThree/>}
          {option.option === 'en' ? <IntroContentImgBoxFourUS/> : option.option === 'ar' ?  <IntroContentImgBoxFourAR/> : <IntroContentImgBoxFour/>}
          {option.option === 'en' ? <IntroContentImgBoxFiveUS/> : option.option === 'ar' ?  <IntroContentImgBoxFiveAR/> : <IntroContentImgBoxFive/>}
          {option.option === 'en' ? <IntroContentImgBoxSixUS/> : option.option === 'ar' ?  <IntroContentImgBoxSixAR/> : <IntroContentImgBoxSix/>}
          {option.option === 'en' ? <IntroContentImgBoxSevenUS/> : option.option === 'ar' ?  <IntroContentImgBoxSevenAR/> : <IntroContentImgBoxSeven/>}
          {option.option === 'en' ? <IntroContentImgBoxEightUS/> : option.option === 'ar' ?  <IntroContentImgBoxEightAR/> : <IntroContentImgBoxEight/>}
          {option.option === 'en' ? <IntroContentImgBoxNineUS/> : option.option === 'ar' ?  <IntroContentImgBoxNineAR/> : <IntroContentImgBoxNine/>}
        </div>
      </div>
    </div>
  );
};
