import React from 'react';
import './NoStudents.scss';
import { no_billing } from '../../../images/MyPage';

const NoStudents = () => {
  return (
    <div className='NoStudents'>
      <img src={no_billing} alt='no_students' />
      <p>참가한 학생이 없어요</p>
      <p>학급 코드 입력을 통해 학급에 가입할 수 있어요.</p>
      {/* <Link to='/pricing'>
        <button>초대하기</button>
      </Link> */}
    </div>
  );
};

export default NoStudents;
