import React, { useEffect } from 'react';
import { DownloadTemplate } from '../components/download/DownloadTemplate';
import { Header } from '../components/base/Header';
import { BannerDownload } from '../components/common/Banner';
import { Footer } from '../components/base/Footer';
import { SchoolDownload } from '../components/download/SchoolDownload';
import { SchoolDownloadSpec } from '../components/download/SchoolDownloadSpec';
import { bgColorAtom, selectAtom } from 'lib/common/atoms';
import { useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';

export const DownloadPage = () => {

  const [option, setOption] = useAtom(selectAtom);
  
  const onChangeOption = (e: { target: { value : string } }) => {
    setOption(e.target.value);
    console.log(e.target.value);
  };


  const [bgColor, setBgColor] = useAtom(bgColorAtom);

  useEffect(() => {
    setBgColor('#FCC500');
  }, [setBgColor]);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let hl = params.get("hl");
    if(hl === 'ko'){
      setOption('ko');
    }else if(hl === 'en'){
      setOption('en');
    }else if(hl === 'ar'){
      setOption('ar');
    }
  }, [ location ])

  return (
    <DownloadTemplate header={<Header option={option} changeOption={onChangeOption} />} footer={<Footer option={option}/>}>
     {option === 'en' ?  <BannerDownload illust={'/images/download/Image_3.png'} bannerText={'DOWNLOAD'} bgColor={bgColor} /> 
     : option === 'ar' ?  <BannerDownload illust={'/images/download/Image_3.png'} bannerText={'تحميل'} bgColor={bgColor} /> 
     :<BannerDownload illust={'/images/download/Image_3.png'} bannerText={'다운로드'} bgColor={bgColor} /> }
      <div className='container'>
        <SchoolDownload option={option} />
        <SchoolDownloadSpec option={option} />
      </div>
    </DownloadTemplate>
  );


};
