import React from 'react';
import './SchoolDownload.scss';


  export const DownloadHeadLine = () => {
    return(
      <div className='download-headline'>
        <p>VRWARE Edu StoryBuilder 다운로드</p>
      </div>
    );
  }

  export const DownloadHeadLineUS = () => {
    return(
      <div className='download-headline'>
        <p>VRWARE Edu StoryBuilder Download</p>
      </div>
    );
  }

  export const DownloadHeadLineAR = () => {
    return(
      <div className='download-headline'>
        <p>VRWARE Edu StoryBuilder تحميل</p>
      </div>
    );
  }


  export const SchoolDownloadHmd = () => {
    return (
      <div className='school-download-hmd'>
        <div className='school-download-hmd-button'>
          <a href='https://play.google.com/store/apps/details?id=com.Globepoint.VRWAREStoryBuilder' target='_sub'>
            <button>
              Android 버전 다운로드
              <img src={'/images/download/Icon_2.png'} alt='download' />
            </button>
          </a>
        </div>
        {/* <p className='school-download-hmd-label'>*안드로이드용 다운로드 ( 태블릿 / 스마트폰 )</p> */}
    </div>
    );
  }
  
  export const SchoolDownloadHmdUS = () => {
    return (
      <div className='school-download-hmd'>
        <div className='school-download-hmd-button'>
          <a href='https://play.google.com/store/apps/details?id=com.Globepoint.VRWAREStoryBuilder' target='_sub'>
            <button>
             Android Download
              <img src={'/images/download/Icon_2.png'} alt='download' />
            </button>
          </a>
        </div>
        {/* <p className='school-download-hmd-label'>*Download for Android ( Tablet / Smartphone )</p> */}
    </div>
    );
  }
  

  export const SchoolDownloadHmdAR = () => {
    return (
      <div className='school-download-hmd'>
        <div className='school-download-hmd-button'>
          <a href='https://play.google.com/store/apps/details?id=com.Globepoint.VRWAREStoryBuilder' target='_sub'>
            <button>
            تحميل نسخة الجوال
              <img src={'/images/download/Icon_2.png'} alt='download' />
            </button>
          </a>
        </div>
        {/* <p className='school-download-hmd-label'>*تحميل للأندرويد*</p> */}
    </div>
    );
  }


  export const SchoolDownloadIOS = () => {
    return (
      <div className='school-download-ios'>
        <div className='school-download-ios-button'>
          <a href='https://apps.apple.com/kr/app/vrware-edu-storybuilder/id6450920692' target='_sub'>
            <button>
              iOS 버전 다운로드
              <img src={'/images/download/Icon_2.png'} alt='download' />
            </button>
          </a>
        </div>
    </div>
    );
  }


  export const SchoolDownloadIOSUS = () => {
    return (
      <div className='school-download-ios'>
        <div className='school-download-ios-button'>
          <a href='https://apps.apple.com/kr/app/vrware-edu-storybuilder/id6450920692' target='_sub'>
            <button>
              iOS Download
              <img src={'/images/download/Icon_2.png'} alt='download' />
            </button>
          </a>
        </div>
    </div>
    );
  }



  export const SchoolDownloadIOSAR = () => {
    return (
      <div>
    </div>
    );
  }
