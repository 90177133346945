import React from 'react';
import './IntroTop.scss';
import { TitleWrap, DescItemOne,
   DescItemOneAR, TitleWrapAR,
TitleWrapUS, DescItemOneUS 
} from '../Intro';

type IntroTopProps = {
  option : any;
}

export const IntroTop = (option : IntroTopProps) => {
  return (
    <div className='IntroTop'>
      {option.option === 'en' ? <TitleWrapUS/> : option.option === 'ar' ? <TitleWrapAR/> : <TitleWrap/>}
      <div className='desc-wrap'>
        <div className='desc-list'>
          {option.option === 'en' ? <DescItemOneUS/> : option.option === 'ar' ? <DescItemOneAR/> : <DescItemOne/>}
        </div>
      </div>
    </div>
  );
};
