import React from 'react';
import { useHistory } from 'react-router-dom';

type HeadersProps = {
  selected : String;
}

const Headers = ( {selected} : HeadersProps ) => {
  const history = useHistory();

  const onClickGoToPath = (path: string) => {
    const { pathname } = history.location;
    if (pathname === path) return;

    history.push(path);
  };

  const HeaderUS = () => {

    return (
              <div 
              style={{fontFamily: "Noto Sans"}}
              className='nav'>
                <ul>
                  <li onClick={() => onClickGoToPath('/intro')}>
                    <label>ABOUT</label>
                  </li>
                  <li onClick={() => onClickGoToPath('/myclass')}>
                    <label>CLASS</label>
                  </li>
                  <li onClick={() => onClickGoToPath('/edu-case')}>
                  <label>EDUCATION</label>
                </li>
                  <li onClick={() => onClickGoToPath('/download')}>
                    <label>DOWNLOAD</label>
                  </li>
                  <li onClick={() => (window.location.href = 'https://my.vrware.us/cs')}>
                    <label>CUSTOMER</label>
                  </li>
                </ul>
              </div>
      );
};

 const Header = () => {

  return (
            <div className='nav'>
              <ul>
                <li onClick={() => onClickGoToPath('/intro')}>
                  <label>콘텐츠 소개</label>
                </li>
                <li onClick={() => onClickGoToPath('/myclass')}>
                  <label>내 학급</label>
                </li>
                <li onClick={() => onClickGoToPath('/edu-case')}>
                  <label>교육사례</label>
                </li>
                <li onClick={() => onClickGoToPath('/download')}>
                  <label>다운로드</label>
                </li>
                <li onClick={() => (window.location.href = 'https://my.vrware.us/cs')}>
                  <label>고객센터</label>
                </li>
              </ul>
            </div>
    );
};

const HeaderAR = () => {

  return (
            <div className='nav'>
              <ul>
                <li onClick={() => onClickGoToPath('/intro')}>
                  <label>صفحة حول</label>
                </li>
                <li onClick={() => onClickGoToPath('/myclass')}>
                  <label>صفّ</label>
                </li>
                <li onClick={() => onClickGoToPath('/edu-case')}>
                  <label>تعليم</label>
                </li>
                <li onClick={() => onClickGoToPath('/download')}>
                  <label>حميل</label>
                </li>
                <li onClick={() => (window.location.href = 'https://my.vrware.us/cs')}>
                  <label>العميل</label>
                </li>
              </ul>
            </div>
    );
};

return(
  <div >
      {
        selected === 'en'
        ? <HeaderUS/>
        : (selected === 'ar'
          ? <HeaderAR/>
          : <Header/>
        )

      }
  </div>
);
 




};


export default Headers;
  
