import React, { useState } from 'react';
import './ClassJoinPopup.scss';
import { Popup } from '../../common/Popup';
import { InputWithLabelBorderEffect } from '../../common/InputWithLabelBorderEffect';
import { SchoolApi } from '../../../lib/api';
import useJoinPopup from 'hooks/myClass/useJoinPopup';

type ClassJoinPopupProps = {
  email?: string;
  loadMyClassList(): void;
};

const ClassJoinPopupUS = ({ email, loadMyClassList }: ClassJoinPopupProps) => {
  const [classCode, setClassCode] = useState('');
  const [notifyClassCode, setNotifyClassCode] = useState('');
  const { isShowJoinPopup, off } = useJoinPopup();

  if (!isShowJoinPopup) return null;

  const onChangeClassCode = (classCode: string) => {
    setClassCode(classCode);

    if (classCode.length === 0) {
      setNotifyClassCode('학급 코드를 입력해 주세요.');
      return false;
    } else if (classCode.length > 0 && !/^[a-zA-Z0-9]{7}$/.test(classCode)) {
      setNotifyClassCode('학급 코드를 정확히 입력해 주세요.');
      return false;
    } else {
      setNotifyClassCode('');
    }
  };

  const onClickJoinClass = async () => {
    if (!classCode) {
      return alert('입력하지 않은 값들이 있는지 확인해주세요.');
    } else if (notifyClassCode) {
      return;
    }

    try {
      const res = await SchoolApi.post(`/classes/${classCode}/students`, {
        email,
      });
      if (res.data.status_code === 200) {
        alert(res.data.alert_message);
        loadMyClassList();
      }
    } catch (error : any) {
      if (error.response.data.status_code >= 400) {
        return alert(error.response.data.alert_message);
      }
    }
  };

  return (
    <Popup>
      <div className='ClassJoinPopup'>
        <div className='class-join-title'>학급 가입</div>
        <div className='class-join-info'>
          <p className='class-join-text'>선생님께서 알려주신 학급 코드 7자리를 입력해주세요.</p>
          <div className='class-join-input-box'>
            <InputWithLabelBorderEffect
              notify={notifyClassCode}
              placeholder='학급 코드를 입력해주세요.'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeClassCode(e.target.value)}
              maxLength={7}
            />
          </div>
          <div className='class-join-button-box'>
            <button className='class-join-button' onClick={onClickJoinClass}>
              가입
            </button>
            <button className='class-join-cancel-button' onClick={off}>
              취소
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default ClassJoinPopupUS;
