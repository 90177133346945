import React from 'react';
import './IntroBottom.scss';
import {IntroPackageWriting, IntroVideoWriting,IntroDevicesWriting, IntroVideoButton,
  IntroPackageWritingAR, IntroVideoWritingAR,IntroDevicesWritingAR, IntroVideoButtonAR,
  IntroPackageWritingUS, IntroVideoWritingUS,IntroDevicesWritingUS, IntroVideoButtonUS
} from './IntroBottoms';

type IntroBottomProps = {
  option : any;
}

export const IntroBottom = ( option : IntroBottomProps) => {
  return (
    <div className='IntroBottom'>
        <div className='intro-bottom-contents-wrap'>
            <hr/>
          <div className='intro-bottom-cotents-package'>
              <div className='intro-image'>
                <img src={'/images/intro/Image_12.png'} alt='img' />
              </div>
              {option.option === 'en' ? <IntroPackageWritingUS/> : option.option === 'ar' ? <IntroPackageWritingAR/> : <IntroPackageWriting/>}
          </div>
          <hr/>
          <div className='intro-bottom-contents-video'>
              <div className='intro-image'>
                <img src={'/images/intro/Image_13.png'} alt='img' />
              </div>
              {option.option === 'en' ? <IntroVideoWritingUS/> : option.option === 'ar' ? <IntroVideoWritingAR/> : <IntroVideoWriting/>}
              {option.option === 'en' ? <IntroVideoButtonUS/> : option.option === 'ar' ? <IntroVideoButtonAR/> : <IntroVideoButton/>}
          </div>
        
          <hr/>
        <div className='intro-bottom-contents-device'>
          {option.option === 'en' ? <IntroDevicesWritingUS/> : option.option === 'ar' ? <IntroDevicesWritingAR/> : <IntroDevicesWriting/>}
          <div className='intro-image'>
              <div className='intro-image-wacom'>
                <img src={'/images/intro/Image_14.png'} alt='img' />
              </div>
              <div className='intro-image-tab'>
                <img src={'/images/intro/Image_15.png'} alt='img' />
              </div>
          </div>
        </div>
      </div>   
    </div>
  );
};
