import React from 'react';
import './CreateOrJoinPopup.scss';
import { Popup } from '../../common/Popup';
import { IoIosClose } from 'react-icons/io';
import useSelectPopup from 'hooks/myClass/useSelectPopup';
import { on as onCreatePopup } from 'hooks/myClass/useCreatePopup';
import { on as onJoinPopup } from 'hooks/myClass/useJoinPopup';

const CreateOrJoinPopupUS = () => {
  const { isShowSelectOptionPopup, off } = useSelectPopup();

  if (!isShowSelectOptionPopup) return null;

  const onClickCreatePopup = () => {
    onCreatePopup();
    off();
  };
  const onClickJoinPopup = () => {
    onJoinPopup();
    off();
  };

  return (
    <Popup>
      <div className='CreateOrJoinPopup'>
        <div className='select-create-join-box'>
          <div className='select-create-join-title'>
            학급 가입s
            <div className='select-create-join-close' onClick={off}>
              <IoIosClose />
            </div>
          </div>
          <div className='select-create-join-info'>
            <div className='select-create-join-button-box'>
              <button className='select-create-join-button' onClick={onClickCreatePopup}>
                생성하기s
              </button>
              <button className='select-create-join-button' onClick={onClickJoinPopup}>
                가입하기s
              </button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default CreateOrJoinPopupUS;
