import React from 'react';
import './MyClassList.scss';
import { MyClassItems } from '../MyClassItem';
import { SchoolApi } from '../../../lib/api';
import { CreateOrEnterClassAR, CreateOrEnterClassUS } from '../CreateOrEnterClass';
import { MyClassInfo } from 'types';

type MyClassListProps = {
  isSession: boolean;
  email?: string;
  myClassList: MyClassInfo[];
  loadMyClassList(): void;
};

const MyClassListsAR = ({ isSession, email, myClassList, loadMyClassList }: MyClassListProps) => {
  const onClickClassDelete = async (classId: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('All the information in the class disappears. Are you sure you want to delete it?') === true) {
      try {
        const res = await SchoolApi.delete('/classes/' + classId);
        if (res.data.status_code === 200) {
          loadMyClassList();
          return alert(res.data.alert_message);
        }
      } catch (error : any) {
        if (error.response.data.status_code >= 400) {
          return alert(error.response.data.alert_message);
        }
      }
    }
  };

  return (
    <div className='MyClassList'>
      <CreateOrEnterClassAR isSession={isSession} />
      {myClassList.map((classInfo) => (
        <MyClassItems
          key={classInfo.classId}
          classInfo={classInfo}
          onClickClassDelete={onClickClassDelete}
          email={email}
        />
      ))}
    </div>
  );
};

export default MyClassListsAR;
