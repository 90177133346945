import React from 'react';
import './CreateOrEnterClass.scss';
import { new_class } from 'images/MyClass';
import { on } from 'hooks/myClass/useSelectPopup';

const CreateOrEnterClass = ({ isSession }: { isSession: boolean }) => {
  const onToggleSelectOption = () => {
    if (!isSession) {
      alert('로그인이 필요한 서비스 입니다.');
      return (window.location.href = 'https://my.vrware.us/login');
    }

    on();
  };

  return (
    <div className='CreateOrEnterClass' onClick={onToggleSelectOption}>
      <div>
        <img src={new_class} alt='new_class' />
      </div>
      <p>학급생성 또는 가입</p>
    </div>
  );
};

export default CreateOrEnterClass;
