import React from 'react';
import './Footer.scss';
import { footer_logo } from 'images/Footer';
import {FooterCenterTop, FooterCenterBottom, FooterRight, FooterBottomSNS, FooterCenterTopUS, FooterCenterBottomUS, FooterRightUS} from './Footers';

type FooterProps = {
  option :  String;
}

const Footer = ( {option} : FooterProps) => {
  return (
    <div className='footer-wrap'>
      <div className='footer-box'>
        <div className='gp-logo'>
          <img src={footer_logo} alt='globepoint' />
        </div>
        <div className='footer-center'>
          <div className='footer-center-text'>
            {option == 'en' ? <FooterCenterTopUS/> : option === 'ar' ? <FooterCenterTopUS/> : <FooterCenterTop/>}
            {option == 'en' ? <FooterCenterBottomUS/> : option === 'ar' ? <FooterCenterBottomUS/> : <FooterCenterBottom/>}
          </div>
        </div>
        <hr className='footer-hr' />
        {option == 'en' ? <FooterRightUS/> : option === 'ar' ? <FooterRightUS/> : <FooterRight/>}
      </div>
      <FooterBottomSNS />
    </div>
  );
};


export default Footer;
