import React from 'react';
import './StudentList.scss';
import { StudentItem } from '../StudentItem';
import { NoStudents } from '../NoStudents';
import { MyClassStudent, MyClassInfo } from 'types';

type StudentListProps = {
  myClassStudents: MyClassStudent[];
  myClassDetail?: MyClassInfo;
};

const StudentList = ({ myClassStudents, myClassDetail }: StudentListProps) => {
  return (
    <React.Fragment>
      <div className='class-list-box'>{'학생 목록'}</div>
      <div className='StudentList'>
        {myClassStudents.map((studentInfo) => (
          <StudentItem key={studentInfo.studentEmail} studentInfo={studentInfo} myClassDetail={myClassDetail} />
        ))}
      </div>
      {myClassStudents.length === 0 && <NoStudents />}
    </React.Fragment>
  );
};

export default StudentList;
