import React from 'react';
import './MyClassTemplate.scss';

type MyClassTemplateProps = {
  header?: JSX.Element;
  children: React.ReactNode;
  footer?: JSX.Element;
};

const MyClassTemplate = ({ header, children, footer }: MyClassTemplateProps) => {
  return (
    <div className='MyClassTemplate'>
      {header}
      <main>{children}</main>
      {footer}
    </div>
  );
};

export default MyClassTemplate;
