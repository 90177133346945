import React, { useState } from 'react';
import { Footer } from 'components/base/Footer';
import { Header } from 'components/base/Header';
import { EduCaseTemplate } from 'components/eduCase/EduCaseTemplate';
import { EduCaseBanner } from 'components/eduCase/EduCaseBanner';
import { eduCaseItem } from 'components/eduCase/EduCaseList/eduCaseData';
import { useAtom } from 'jotai';
import { selectAtom } from '../lib/common/atoms';
import EduCaseListUS from 'components/eduCase/EduCaseList/EduCaseListUS';
import { EduCaseListAR } from 'components/eduCase/EduCaseList';

const EduCasePage = () => {
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [eduCaseInfo, setEduCaseInfo] = useState<eduCaseItem>();

  const onClickEduCase = (eduCaseInfo: eduCaseItem) => {
    setIsShowPopup(!isShowPopup);
    setEduCaseInfo(eduCaseInfo);
  };

  const onClickClosePopup = () => {
    setIsShowPopup(false);
    setEduCaseInfo(undefined);
  };

  const [option, setOption] = useAtom(selectAtom);
  
  const onChangeOption = (e: { target: { value : string } }) => {
    setOption(e.target.value);
    console.log(e.target.value);
  };


  return (
    <EduCaseTemplate header={<Header option={option} changeOption={onChangeOption}/>} footer={<Footer option={option}/>}>
      {option === 'en' ? <EduCaseBanner bannerText='EDUCATIONAL CASES' illust={'/images/eduCase/edu_illust.png'} /> : option === 'ar' ? <EduCaseBanner bannerText='استخداماتها السابقة في التعليم' illust={'/images/eduCase/edu_illust.png'} /> : <EduCaseBanner bannerText='교육사례' illust={'/images/eduCase/edu_illust.png'} /> } 
      <div className='container'>
        {option === 'ar' ? <EduCaseListAR onClickEduCase={onClickEduCase}/> : <EduCaseListUS onClickEduCase={onClickEduCase} /> }
      </div>
      {/* <EduCaseDetailPopup eduCaseInfo={eduCaseInfo} isShowPopup={isShowPopup} onClickClosePopup={onClickClosePopup} /> */}
    </EduCaseTemplate>
  );
};

export default EduCasePage;
