import React from 'react';
import { DownloadSpecTable, DownloadHeadLine, DownloadSpecHead, DownloadSpecHeadUS, DownloadSpecCPU, DownloadSpecRAM, DownloadSpecHDD, DownloadSpecOS, DownloadSpecHeadAR } from './SchoolDownloadSpecs';


import { DownloadSpecTablePC, DownloadSpecCPUPC, DownloadSpecRAMPC, DownloadSpecHDDPC, DownloadSpecVGAPC, DownloadSpecOSPC, DownloadHeadLinePC, DownloadSpecHeadUSPC, DownloadSpecHeadPC, DownloadSpecHeadARPC } from './SchoolDownloadSpecsPC';



type SchoolDownloadSpecProps = {
  option : string;
}

const SchoolDownloadSpec = ({option} : SchoolDownloadSpecProps) => {
  return (
    <div className='SchoolDownloadSpec'>
      {option === 'en' ? <DownloadHeadLinePC headLineTxt='PC Recommended Specifications'/> : option === 'ar' ? <DownloadHeadLinePC headLineTxt='دليل المواصفات الموصى به للكبيوتر'/> : <DownloadHeadLinePC headLineTxt='PC 권장사양 안내'/>}
      <div className='download-spec-table-wrap'>
        <DownloadSpecTablePC>
          {option === 'en' ? <DownloadSpecHeadUSPC /> : option === 'ar' ? <DownloadSpecHeadARPC/> : <DownloadSpecHeadPC />}
          <tbody>
            {option === 'en' ? <DownloadSpecCPUPC partTxt='Processor(CPU)' specTxt='Intel® Core™ i3 or higher' /> 
            : option === 'ar' ? <DownloadSpecCPUPC partTxt='مُعالج' specTxt='Intel® Core™ i3 or أكثر' />
            :<DownloadSpecCPUPC partTxt='프로세서(CPU)' specTxt='Intel® Core™ i3 이상 지원' />}

            {option === 'en' ? <DownloadSpecRAMPC partTxt='Memory(RAM)' specTxt='8GB RAM or higher' /> 
            : option === 'ar' ? <DownloadSpecRAMPC partTxt='ذاكرة' specTxt='8GB RAM أكثر' />
            : <DownloadSpecRAMPC partTxt='메모리(RAM)' specTxt='8GB RAM 이상' />}

            {option === 'en' ? <DownloadSpecVGAPC partTxt='Graphic(VGA)' specTxt='NVIDIA® GeForce® GTX 760 or higher' /> 
            : option === 'ar' ? <DownloadSpecVGAPC partTxt='بطاقة العرض المرئي' specTxt='NVIDIA® GeForce® GTX 760 أكثر' />
            : <DownloadSpecVGAPC partTxt='그래픽(VGA)' specTxt='NVIDIA® GeForce® GTX 760 이상' />}

            {option === 'en' ? <DownloadSpecHDDPC partTxt='Storage' specTxt='20GB or higher' /> 
            : option === 'ar' ? <DownloadSpecHDDPC partTxt='مساحة القرص الصلب ' specTxt='20GB أكثر' />
            : <DownloadSpecHDDPC partTxt='저장용량(Storage)' specTxt='20GB 이상' />}
            
            {option === 'en' ? <DownloadSpecOSPC partTxt='Operation System(OS)' specTxt='Windows® 10 64-bit' /> 
            : option === 'ar' ? <DownloadSpecOSPC partTxt='نظام التشغيل' specTxt='Windows® 10 64-bit' />
            : <DownloadSpecOSPC partTxt='운영체제(OS)' specTxt='Windows® 10 64-bit' />}
          </tbody>
        </DownloadSpecTablePC>
      </div>
      {option === 'en' ? <DownloadHeadLine headLineTxt='Mobile Recommended Specifications'/> : option === 'ar' ? <DownloadHeadLine headLineTxt='توصيات الأجهزة اللوحية والهواتف الذكية'/> : <DownloadHeadLine headLineTxt='스마트폰 권장사양 안내'/>}
      <div className='download-spec-table-wrap'>
        <DownloadSpecTable>
           {option === 'en' ? <DownloadSpecHeadUS /> : option === 'ar' ? <DownloadSpecHeadAR/> : <DownloadSpecHead />}
          <tbody>
            {option === 'en' ? <DownloadSpecCPU partTxt='Processor(CPU)' specTxt='Qualcomm Snapdragon 660' /> 
            : option === 'ar' ? <DownloadSpecCPU partTxt='مُعالج' specTxt='Qualcomm Snapdragon 660' />
            : <DownloadSpecCPU partTxt='프로세서(CPU)' specTxt='Qualcomm Snapdragon 660' />}

            {option === 'en' ? <DownloadSpecRAM partTxt='Memory(RAM)' specTxt='4GB RAM ' /> 
            : option === 'ar' ? <DownloadSpecRAM partTxt='ذاكرة' specTxt='4GB RAM ' />
            : <DownloadSpecRAM partTxt='메모리(RAM)' specTxt='4GB RAM ' />}

            {option === 'en' ? <DownloadSpecHDD partTxt='Storage' specTxt='64GB or higher' /> 
            :option === 'ar' ? <DownloadSpecHDD partTxt='مساحة القرص الصلب ' specTxt='64GB أكثر' />
            : <DownloadSpecHDD partTxt='저장용량(Storage)' specTxt='64GB 이상' />}
            
            {option === 'en' ? <DownloadSpecOS partTxt='Operating System(OS)' specTxt='Android 10.0 or higher' /> 
            : option === 'ar' ? <DownloadSpecOS partTxt='نظام التشغيل' specTxt='Android 10.0 أكثر' /> 
            : <DownloadSpecOS partTxt='운영체제(OS)' specTxt='Android 10.0 이상' />}
          </tbody>
        </DownloadSpecTable>
      </div>
    </div>
  );
};

export default SchoolDownloadSpec;