import React from 'react';
import './SchoolIntroVideo.scss';
import ReactPlayer from 'react-player';

type SchoolIntroVideoProps = {
  option:String;
  isShowVideo: boolean;
  off(): void;
};

const SchoolIntroVideo = ({ option, isShowVideo, off }: SchoolIntroVideoProps) => {
  if (!isShowVideo) return null;
  return (
    <div className='SchoolIntroVideo' onClick={off}>
      <div className='SchoolIntroVideo-inner'>
        {/* <iframe src="https://www.youtube.com/embed/gEllR0SEjhQ" frameborder="0" /> */}
        {option === 'en' ? 
        <ReactPlayer url="https://www.youtube.com/watch?v=s5xh6aM6Uls" width='100%' height='100%' controls={true} /> : 
        option === 'ar' ? <ReactPlayer url='https://www.youtube.com/watch?v=s5xh6aM6Uls' width='100%' height='100%' controls={true} /> 
      : <ReactPlayer url='https://www.youtube.com/watch?v=6woIBJyuKDc' width='100%' height='100%' controls={true} />}
      </div>
    </div>
  );
};

export default SchoolIntroVideo;
