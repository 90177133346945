import React from 'react';
import './HeaderRight.scss';
import { HeaderLogin } from '../HeaderLogin';
import { HeaderNotLogin } from '../HeaderNotLogin';

type HeaderRightProps = {
  isSession?: boolean;
  onClickLogout(): Promise<void>;
  selected : String;
};

const HeaderRight = ({ isSession, onClickLogout, selected }: HeaderRightProps) => {
  return (
    <div className='HeaderRight'>{!isSession ? <HeaderNotLogin selected={selected}/> : <HeaderLogin selected={selected} onClickLogout={onClickLogout} />}</div>
  );
};

export default HeaderRight;
