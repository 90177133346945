import React from 'react';

export const TitleWrap = () => {
  return (
    <div className='title-wrap'>
      <h2>창작이 가능한 스토리북 저작 솔루션</h2>
     
      <p>VRWARE Edu StoryBuilder는 자신의 아이디어 바탕으로 명작 동화를 </p>
      <p>나만의 VR 스토리북으로 재구성하는 메타버스기반 스토리텔링교육 창작 솔루션입니다. </p>
      <p>엄선된 명작 동화책에서 뽑은 이미지를 활용하여 페이지를 구성하거나, </p>
      <p>제공되는 다양한 스티커 및 꾸미기 재료, 말풍선, 녹음기능을 활용할 수도 있습니다.</p> 
      <p>저작한 콘텐츠는 저장하여 친구들과도 공유도 하고, VR모드로 체험할 수 있습니다. </p>
      <div className='title-button'>
        <button onClick={() => window.location.replace('/download?hl=ko')}>시작하기</button>
      </div>
      <div className='title-img-box'>
        <img src={'/images/intro/Image_2.png'} alt='img' />
      </div>
  </div>
  );
}

export const TitleWrapUS = () => {
  return (
    <div className='title-wrap'>
      <h2>Creative Storybook Authoring Solution</h2>
     
      <p>VRWARE Edu StoryBuilder allows users to create their own story based on classic masterpiece fairy tales. </p>
      <p>StoryBuilder is a metaverse-based storytelling creation solution which reconstructs ideas into an educational</p>
      <p>VR storybook.Use images selected from various recognizable children’s books to compose your own story. </p>
      <p>Additionally, decorate your story with cute stickers, speech bubbles, and voice recording functionality.</p> 
      <p>You can save the self-created content, share it with your friends, and even experience it in VR mode. </p>
      <div className='title-button'>
        <button onClick={() => window.location.replace('/download?hl=en')}>Get Started</button>
      </div>
      <div className='title-img-box'>
        <img src={'/images/intro/Image_2.png'} alt='img' />
      </div>
  </div>
  );
}

export const TitleWrapAR = () => {
  return (
    <div className='title-wrap'>
      <h2>حل لكتابة القصص الإبداعية</h2>
     
      <p>بإنشاء قصتهم الخاصة بناءً على VRWARE Edu StoryBuilder سمح للمستخدمين </p>
      <p>الحكايات الخيالية الأسطورية الكلاسيكية. يعد StoryBuilder حلًا لإنشاء قصة قائمة على</p>
      <p>الكتب الإلكترونية التعليمية في الميتافيرس، الذي يعيد بناء الأفكار والقصص بطريقة </p>
      <p>تربوية وبصرية. يمكن استخدام الصور المختارة من مجموعة من كتب الأطفال المعروفة</p> 
      <p>لإنشاء القصة الخاصة بك. كما يمكنك تزيين قصتك بملصقات جميلة وحوارات فقاعات</p>
      <p>الكلام، بالإضافة إلى ميزة التسجيل الصوتي. يمكنك حفظ المحتوى الذي تم إنشاؤه</p>
      <p>بنفسك ومشاركته مع أصدقائك وحتى تجربته في وضع الواقع الافتراضي.</p>
      <div className='title-button'>
        <button onClick={() => window.location.replace('/download?hl=ar')}>ابدأ الآن</button>
      </div>
      <div className='title-img-box'>
        <img src={'/images/intro/Image_2.png'} alt='img' />
      </div>
  </div>
  );
}

export const DescItemOne = () => {
    return(
    <div className='desc-item'>
        <div className='desc-img-box'>
        <img src={'/images/intro/Icon_group.png'} alt='img' />
        </div>
  </div>
  );
}

export const DescItemOneUS = () => {
    return(
    <div className='desc-item'>
        <div className='desc-img-box'>
        <img src={'/images/intro/Icon_groupEn.png'} alt='img' />
        </div>
  </div>
  );
}


export const DescItemOneAR = () => {
  return(
  <div className='desc-item'>
      <div className='desc-img-box'>
      <img src={'/images/intro/Icon_groupAR.png'} alt='img' />
      </div>
</div>
);
}