import React from 'react';
import './IntroMiddle.scss';

export const IntroContentImgBoxOne = () => {
return(
    <div className='intro-content-item'>
    <div className='intro-content-img-box'>
      <img src={'/images/intro/Image_3.png'} alt='img' />
      <p>페이지별 이미지를 자유롭게 선택</p>
    </div>
  </div>
);
}

export const IntroContentImgBoxOneUS = () => {
return(
    <div className='intro-content-item'>
    <div className='intro-content-img-box'>
      <img src={'/images/intro/Image_3.png'} alt='img' />
        <p>Freely select images by page</p>
    </div>
  </div>
);
}

export const IntroContentImgBoxOneAR = () => {
  return(
      <div className='intro-content-item'>
      <div className='intro-content-img-box'>
        <img src={'/images/intro/Image_3.png'} alt='img' />
          <p>اختر الصور بحرية</p>
      </div>
    </div>
  );
  }

export const IntroContentImgBoxTwo = () => {
  return(
      <div className='intro-content-item'>
      <div className='intro-content-img-box'>
        <img src={'/images/intro/Image_4.png'} alt='img' />
        <p>말풍선으로 이야기 만들기</p>
      </div>
    </div>
  );
  }
  
  export const IntroContentImgBoxTwoUS = () => {
  return(
      <div className='intro-content-item'>
      <div className='intro-content-img-box'>
        <img src={'/images/intro/Image_4.png'} alt='img' />
          <p>Create a story with speech bubbles</p>
      </div>
    </div>
  );
  }

  export const IntroContentImgBoxTwoAR = () => {
    return(
        <div className='intro-content-item'>
        <div className='intro-content-img-box'>
          <img src={'/images/intro/Image_4.png'} alt='img' />
            <p>أنشئ قصة مع فقاعات الحوار</p>
        </div>
      </div>
    );
    }

  export const IntroContentImgBoxThree = () => {
    return(
        <div className='intro-content-item'>
        <div className='intro-content-img-box'>
          <img src={'/images/intro/Image_5.png'} alt='img' />
          <p>귀여운 스티커 꾸미기</p>
        </div>
      </div>
    );
    }
    
    export const IntroContentImgBoxThreeUS = () => {
    return(
        <div className='intro-content-item'>
        <div className='intro-content-img-box'>
          <img src={'/images/intro/Image_5.png'} alt='img' />
            <p>Decorate with various cute stickers</p>
        </div>
      </div>
    );
    }

    export const IntroContentImgBoxThreeAR = () => {
      return(
          <div className='intro-content-item'>
          <div className='intro-content-img-box'>
            <img src={'/images/intro/Image_5.png'} alt='img' />
              <p>ازين القصة بمجموعة متنوعة من</p>
              <p>الملصقات الجميلة</p>
          </div>
        </div>
      );
      }

    export const IntroContentImgBoxFour = () => {
      return(
          <div className='intro-content-item'>
          <div className='intro-content-img-box'>
            <img src={'/images/intro/Image_6.png'} alt='img' />
            <p>다양한 배경음악 선택</p>
          </div>
        </div>
      );
      }
      
      export const IntroContentImgBoxFourUS = () => {
      return(
          <div className='intro-content-item'>
          <div className='intro-content-img-box'>
            <img src={'/images/intro/Image_6.png'} alt='img' />
              <p>Select from various background music</p>
          </div>
        </div>
      );
      }

      export const IntroContentImgBoxFourAR = () => {
        return(
            <div className='intro-content-item'>
            <div className='intro-content-img-box'>
              <img src={'/images/intro/Image_6.png'} alt='img' />
                <p>اختر من مجموعة متنوعة</p>
                <p>من الموسيقى الخلفية</p>
            </div>
          </div>
        );
        }

        
    export const IntroContentImgBoxFive = () => {
      return(
          <div className='intro-content-item'>
          <div className='intro-content-img-box'>
            <img src={'/images/intro/Image_7.png'} alt='img' />
            <p>다채로운 색으로 색칠하기</p>
          </div>
        </div>
      );
      }
      
      export const IntroContentImgBoxFiveUS = () => {
      return(
          <div className='intro-content-item'>
          <div className='intro-content-img-box'>
            <img src={'/images/intro/Image_7.png'} alt='img' />
              <p>Color with full color palette</p>
          </div>
        </div>
      );
      }

      export const IntroContentImgBoxFiveAR = () => {
        return(
            <div className='intro-content-item'>
            <div className='intro-content-img-box'>
              <img src={'/images/intro/Image_7.png'} alt='img' />
                <p>استخدم لوحة ألوان تفصيلية</p>
                <p>للتلوين</p>
            </div>
          </div>
        );
        }

          export const IntroContentImgBoxSix = () => {
      return(
          <div className='intro-content-item'>
          <div className='intro-content-img-box'>
            <img src={'/images/intro/Image_8.png'} alt='img' />
            <p>내 목소리로 녹음하기</p>
          </div>
        </div>
      );
      }
      
      export const IntroContentImgBoxSixUS = () => {
      return(
          <div className='intro-content-item'>
          <div className='intro-content-img-box'>
            <img src={'/images/intro/Image_8.png'} alt='img' />
              <p>Record your own voice</p>
          </div>
        </div>
      );
      }

      export const IntroContentImgBoxSixAR = () => {
        return(
            <div className='intro-content-item'>
            <div className='intro-content-img-box'>
              <img src={'/images/intro/Image_8.png'} alt='img' />
                <p>سجّل صوتك الخاص</p>
            </div>
          </div>
        );
        }

    export const IntroContentImgBoxSeven = () => {
      return(
          <div className='intro-content-item'>
          <div className='intro-content-img-box'>
            <img src={'/images/intro/Image_9.png'} alt='img' />
            <p>자유롭게 그리기</p>
          </div>
        </div>
      );
      }
      
      export const IntroContentImgBoxSevenUS = () => {
      return(
          <div className='intro-content-item'>
          <div className='intro-content-img-box'>
            <img src={'/images/intro/Image_9.png'} alt='img' />
              <p>Free draw sketchbook</p>
          </div>
        </div>
      );
      }

      export const IntroContentImgBoxSevenAR = () => {
        return(
            <div className='intro-content-item'>
            <div className='intro-content-img-box'>
              <img src={'/images/intro/Image_9.png'} alt='img' />
                <p>كتاب تلوين مجاني</p>
                <p>للرسم الحر</p>
            </div>
          </div>
        );
        }
  

      export const IntroContentImgBoxEight = () => {
        return(
            <div className='intro-content-item'>
            <div className='intro-content-img-box'>
              <img src={'/images/intro/Image_10.png'} alt='img' />
              <p>만든 북 저장해서 보기</p>
            </div>
          </div>
        );
        }
        
        export const IntroContentImgBoxEightUS = () => {
        return(
            <div className='intro-content-item'>
            <div className='intro-content-img-box'>
              <img src={'/images/intro/Image_10.png'} alt='img' />
                <p>View your e-book in view mode</p>
            </div>
          </div>
        );
        }

        export const IntroContentImgBoxEightAR = () => {
          return(
              <div className='intro-content-item'>
              <div className='intro-content-img-box'>
                <img src={'/images/intro/Image_10.png'} alt='img' />
                  <p>عرض كتابك في</p>
                  <p>وضع المشاهدة</p>
              </div>
            </div>
          );
          }
  

        export const IntroContentImgBoxNine = () => {
          return(
              <div className='intro-content-item'>
              <div className='intro-content-img-box'>
                <img src={'/images/intro/Image_11.png'} alt='img' />
                <p>VR모드로 보기</p>
              </div>
            </div>
          );
          }
          
          export const IntroContentImgBoxNineUS = () => {
          return(
              <div className='intro-content-item'>
              <div className='intro-content-img-box'>
                <img src={'/images/intro/Image_11.png'} alt='img' />
                  <p>View books created in VR mode</p>
              </div>
            </div>
          );
          }

          export const IntroContentImgBoxNineAR = () => {
            return(
                <div className='intro-content-item'>
                <div className='intro-content-img-box'>
                  <img src={'/images/intro/Image_11.png'} alt='img' />
                    <p>عرض الكتب التي تم إنشاؤها</p>
                    <p>في وضع الواقع الافتراضي</p>
                </div>
              </div>
            );
            }