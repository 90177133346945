import React from 'react';
import './CreateOrEnterClass.scss';
import { new_class } from 'images/MyClass';
import { on } from 'hooks/myClass/useSelectPopup';

const CreateOrEnterClassUS = ({ isSession }: { isSession: boolean }) => {
  const onToggleSelectOption = () => {
    if (!isSession) {
      alert('This service requires login.');
      return (window.location.href = 'https://my.vrware.us/login');
    }

    on();
  };

  return (
    <div className='CreateOrEnterClass' onClick={onToggleSelectOption}>
      <div>
        <img src={new_class} alt='new_class' />
      </div>
      <p>Create or Join a Class</p>
    </div>
  );
};

export default CreateOrEnterClassUS;
