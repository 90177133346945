import React from 'react';
import './HeaderLogin.scss';

type HeaderLoginProps = {
  onClickLogout(): Promise<void>;
  selected : String;
};

const HeaderLogin = ({ onClickLogout, selected }: HeaderLoginProps) => {

  const HeaderLogin = () => {
    return (
      <div className='HeaderLogin'>
        <li onClick={() => (window.location.href = 'https://my.vrware.us/mypage')}>
          <button className='login-button'>마이페이지</button>
        </li>
        <li onClick={onClickLogout}>
          <button className='login-button'>로그아웃</button>
        </li>
      </div>
    );
  };

  const HeaderLoginUS = () => {
    return (
      <div className='HeaderLogin'>
        <li onClick={() => (window.location.href = 'https://my.vrware.us/mypage?hl=en')}>
          <button className='login-button'>My Page</button>
        </li>
        <li onClick={onClickLogout}>
          <button className='login-button'>Logout</button>
        </li>
      </div>
    );
  }

  const HeaderLoginAR = () => {
    return (
      <div className='HeaderLogin'>
        <li onClick={() => (window.location.href = 'https://my.vrware.us/mypage?hl=en')}>
          <button className='login-button-ar'>صفحتي</button>
        </li>
        <li onClick={onClickLogout}>
          <button className='login-button-ar'>تسجيل خروج</button>
        </li>
      </div>
    );
  }


  return (
    <div>
        {
          selected === 'en' ? <HeaderLoginUS/> : 
          selected === 'ar' ? <HeaderLoginAR/> : <HeaderLogin/>
        }
    </div>
  );
};

export default HeaderLogin;
