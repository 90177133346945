import React, { useEffect, useState } from 'react';
import { eduCaseItem, eduCaseList } from './eduCaseData';
import Pagination from 'rc-pagination';
import './EduCaseListAR.scss';

type EduCaseListProps = {
  onClickEduCase(eduCaseItem: eduCaseItem): void;
};

const EduCaseListUS = ({ onClickEduCase }: EduCaseListProps) => {
  const categoryList = ['ALL'];
  const categoryListAR = ['جميع الصور'];
  const [limit, setLimit] = useState<number>(12);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [category, setCategory] = useState<string>('ALL');
  const onClickFilter = (category: string) => {
    setCurrentPage(1);
    setCategory(category);
  };

  const onClickPage = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setLimit(4);
    } else if (screenWidth <= 1200) {
      setLimit(6);
    }
    if (screenWidth >= 1920) {
      setLimit(12);
    }
  }, []);

  return (
    <div className='EduCaseListAR'>
      <div className='edu-case-category-list'>

        {categoryListAR.map((categoryName) =>
          <label className={categoryName}>{categoryName}</label>
        )}
        {categoryList.map((categoryName) => (
          <button
            key={categoryName}
            className={categoryName === category ? 'category-active ' : ''}
            onClick={() => onClickFilter(categoryName)}
          >
            {categoryName}
          </button>
        ))}
      </div>
      <div className='edu-case-list'>
          <img src='/images/eduCase/eduCase.png'/>
      </div>
      <Pagination
        className='pagination'
        total={1000}
        current={currentPage}
        onChange={onClickPage}
        pageSize={limit}
        prevIcon={' <'}
        nextIcon={' >'}
        jumpPrevIcon={'<<'}
        jumpNextIcon={'>>'}
      />
    </div>
  );
};

export default EduCaseListUS;
